import React, { useEffect, useState } from "react";
import { Checkbox, makeStyles } from "@material-ui/core";
import { Header, Title } from "../components";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Grid from "@material-ui/core/Grid";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { colors } from "../themes/colors";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import Modal from "react-modal";
import { Link, Redirect } from "react-router-dom";
import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import misterCoockyApi from "../constante/apiUrl";
import { CircularProgress } from "@material-ui/core";
import zIndex from "@material-ui/core/styles/zIndex";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "999",
  },
};

const   getMuiTheme = () =>
createTheme({
  components: {
    MUIDataTable: {
      styleOverrides: {
        root: {
          width: "100vw",
        },
        Paper: {
          padding: 10
        }
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          padding: 10
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        filterPaper: {
          width: "30vw"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: "5px solid rgba(0, 0, 0, 0.1)",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          zIndex: "0",
          fontSize: 14,    
        },
        root: {
          fontSize: 14,
          textAlign: "center",
          borderBottom: "3px solid rgba(0, 0, 0, 0.1)",
        }
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
          textTransform: "none",
          alignItems: "center"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          fontSize: 14,
          textTransform: "none",
          alignItems: "center"
        }
      }
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          '& .MuiToolbar-root': {
            backgroundColor: 'white',
          },
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({

  btn: {
    padding: theme.spacing(2),
    color: "#000",
    width: "100%",
    fontWeight: "600",
    textTransform: "none",
    height: "fit-content",
    borderRadius: "10px",
    paddingTop: 5,
    paddingBottom: 5,
    "&:hover": {
      color: "red",
    },
  },
  spinner: {
    marginTop: 20,
  },
}));

/**
 * Page d'affichage des Produits de l'utilisateur
 * @returns {*}
 * @constructor
 */
function MyProductPage() {
  const [data, setData] = useState([]); //produits de l'utilisateur
  const [selectedItemId, setSelectedItemId] = useState(false); //produits de l'utilisateur
  const [selectedItemIdToDelete, setSelectedItemIdToDelete] = useState(null); //produits de l'utilisateur
  const [dataLoaded, setDataLoaded] = useState(false);
  const classes = useStyles();

  function arrangeParameters(arr) {
    var items = [];
    arr.forEach((i) => {
      items.push({
        product: i.product.name,
        mark: i.mark.name,
        ttcPrice: i.ttcPrice.toFixed(2) + " €",
        pet: i.pet.name,
        offer: i.offer.name,
        category: i.category.name,
        discount:
          "-" +
          new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(((i.ttcPrice * i.discount) / 100))
          +
          " (-" +
          i.discount +
          "%)",
        isSMR: {
          isSmart: i.isSmart ? "Oui" : "Non",
          isHealthy: i.isHealthy ? "Oui" : "Non",
          isResponsible: i.isResponsible ? "Oui" : "Non",
        },
        isHealthy: i.isHealthy ? "Oui" : "Non",
        isSmart: i.isSmart ? "Oui" : "Non",
        isResponsible: i.isResponsible ? "Oui" : "Non",
        img: i.uri,
        id: i.id,
        price: {
          initialPrice: new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(i.ttcPrice),
          discountedPrice: new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format((i.ttcPrice - (i.ttcPrice * i.discount) / 100)),
        },
        carriers: i.carriers.map(item => <li style={{listStyleType: 'none', backgroundColor: "rgb(220, 220, 220)", borderRadius: 10, marginBottom: 5, padding: 5}} key={item.id}>{item.nickname}</li>),
        weight: i.weight + " kg",
        dimensions: {
          length: i.length,
          width: i.width,
          height: i.height,
        },
      });
    });
    setData(items);
  }

  const options = {
    selectableRows: false,
    responsive: true,
    textLabels: {
      body: {
        noMatch: "Aucun produit trouvé",
        toolTip: "Trier",
      },
      pagination: {
        next: "Page suivante",
        previous: "Page précédente",
        rowsPerPage: "Produits par page: ",
        displayRows: "sur",
      },
      toolbar: {
        search: "Rechercher un produit",
        downloadCsv: "Télécharger les produits au format CSV",
        print: "Imprimer les produits",
        viewColumns: "Afficher les colonnes",
        filterTable: "Trier le tableau",
      },
      filter: {
        all: "Tous",
        title: "Filtres",
        reset: "Réinitialiser",
      },
      viewColumns: {
        title: "Colonnes à afficher",
        titleAria: "Afficher/Cacher colonne",
      },
      selectedRows: {
        text: "Produit(s) sélectionné(s)",
        delete: "Supprimer",
        deleteAria: "Supprimer produit(s) sélectionné(s)",
      },
    },
  };
  const columns = [
    {
      name: "img",
      label: "Image",
      options: {
        customBodyRender: function (data) {
          if (data) {
            const dataImg = "data:image/png;base64," + data;
            return <img src={dataImg} width={80} />;
          } else return "N/A";
        },
        filter: false,
        sort: false,
        download: false,
      },
    },
    {
      name: "product",
      label: "Nom",
    },
    {
      name: "mark",
      label: "Marque",
    },
    {
      name: "price",
      label: "Prix affiché",
      options: {
        customBodyRender: function (data) {
          console.log(data);
          return (
            <div style={{ textAlign: "center" }} title= {"Prix initial : " + data?.initialPrice}>
              {data?.discountedPrice}
            </div>
          );
        },
        sort: false,
        download: false,
        filter: false
      },
    },
    {
      name: "discount",
      label: "Discount",
    },
    {
      name: "pet",
      label: "Animal",
    },
    {
      name: "offer",
      label: "Type de produit",
    },
    {
      name: "category",
      label: "Catégorie",
    },
    {
      name: "carriers",
      label: "Transporteurs",
      options: {
        customBodyRender: function (data) {
          return (
            <div style={{ textAlign: "center", }}>
              {data}
            </div>
          );
        },
        sort: false,
        download: false,
        filter: false
      },
    },
    {
      name: "weight",
      label: "Poids (kg)",
    },
    {
      name: "dimensions",
      label: "Dimensions (mm)",
      options: {
        customBodyRender: function (data) {
          return (
            <div style={{ textAlign: "center", }}>
              {data.length} x {data.width} x {data.height} mm
            </div>
          );
        },
        sort: false,
        download: false,
        filter: false
      },
    },
    {
      name: "isSMR",
      label: "Critères SMR",
      options: {
        customBodyRender: function (data) {
          return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              {data.isHealthy === "Oui" ? <li style={{listStyle: "none", width: "fit-content", backgroundColor: "rgb(220, 220, 220)", borderRadius: 10, marginBottom: 5, padding: 5}}> Sain </li> : null}
              {data.isSmart === "Oui" ? <li style={{listStyle: "none", width: "fit-content", backgroundColor: "rgb(220, 220, 220)", borderRadius: 10, marginBottom: 5, padding: 5}}> Malin </li> : null}
              {data.isResponsible === "Oui" ? <li style={{listStyle: "none", width: "fit-content", backgroundColor: "rgb(220, 220, 220)", borderRadius: 10, marginBottom: 5, padding: 5}}> Responsable </li> : null}
            </div>
          );
        },
        sort: false,
        download: false,
        filter: false
      },
    },
    {
      name: "isHealthy",
      label: "Sain",
      options: {
        display: "excluded",
        customFilterListOptions: {
          render: (v) => {
            if (v === "Oui") {
              return "Inclut : Sain";
            }
            if (v === "Non") {
              return "N'inclut pas : Sain";
            }
          },
        },
      },
    },
    {
      name: "isSmart",
      label: "Malin",
      options: {
        display: "excluded",
        customFilterListOptions: {
          render: (v) => {
            if (v === "Oui") {
              return "Inclut : Malin";
            }
            if (v === "Non") {
              return "N'inclut pas : Malin";
            }
          },
        },
      },
    },
    {
      name: "isResponsible",
      label: "Responsable",
      options: {
        display: "excluded",
        customFilterListOptions: {
          render: (v) => {
            if (v === "Oui") {
              return "Inclut : Responsable";
            }
            if (v === "Non") {
              return "N'inclut pas : Responsable";
            }
          },
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: function (res) {
          return (
            <div>
              <IconButton
                aria-label="Editer"
                style={{ padding: "3px" }}
                onClick={() => setSelectedItemId(res)}
              >
                <EditOutlinedIcon style={{ color: colors.grey }} />
              </IconButton>
              <IconButton
                aria-label="Supprimer"
                style={{ padding: "3px" }}
                onClick={() => setSelectedItemIdToDelete(res)}
              >
                <DeleteOutlinedIcon style={{ color: colors.grey }} />
              </IconButton>
            </div>
          );
        },
        filter: false,
        download: false,
        print: false,
        sort: false,
      },
    },
  ];

  useEffect(() => {
    refreshList();
  }, []);

  /**
   * Suppression de l'item sélectionné
   * @param id
   */
  function deleteItem(id) {
    setDataLoaded(false);
    fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
                    mutation DeleteItemById($id: String!) {
                      deleteItemById(id: $id)
                    }`,
        variables: {
          id: id,
        },
      }),
    })
      .then((res) => res.json())
      .then(function (result) {
        refreshList();
        setSelectedItemIdToDelete(null);
      });
  }

  /**
   * Récupération des produits
   */
  function refreshList() {
    
    fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
      },
      body: JSON.stringify({
        query: `
                    query{​​​​​​​
                        getItemsBySupplierId(id:"${localStorage.getItem(
                          "supplierId"
                        )}"){​​​​​​​
                            product{
                                name
                            }
                            mark{
                                name
                            }
                            ttcPrice
                            discount
                            pet{
                                name
                            }
                            offer{
                                name
                            }
                            category{
                                name
                            }
                            subCategories{
                                name
                            }
                            isHealthy
                            isResponsible
                            isSmart
                            uri
                            id
                            weight
                            length
                            width
                            height
                            carriers{
                              id,
                              carrierCode
                              nickname
                              friendlyName
                            }
                          }​​​​​​​
                    }`,
      }),
    })
      .then((res) => res.json())
      .then(function (result) {
        arrangeParameters(result.data.getItemsBySupplierId);
        console.log(result.data);
        setDataLoaded(true);
      });
  }

  return (
    <div>
      {selectedItemId ? (
        <Redirect
          to={{
            pathname: "/addProduct",
            state: { itemId: selectedItemId },
          }}
        />
      ) : null}
      <Header />
      <Modal
          isOpen={selectedItemIdToDelete !== null}
          onRequestClose={() => setSelectedItemIdToDelete(null)}
          style={customStyles}
        >
          <Grid>Êtes vous sûr de vouloir supprimer l'article?</Grid>
          <Button onClick={() => deleteItem(selectedItemIdToDelete)}>
            Oui
          </Button>
          <Button onClick={() => setSelectedItemIdToDelete(null)}>Non</Button>
        </Modal>
      <div style={{ width: "96vw", paddingLeft: 0, paddingRight: 0, margin: "0 auto" }}>

        <div style={{ marginLeft: 30 }}>
          <Title name={"Mr.Market — Produits"} />
        </div>

        <Grid container spacing={5} justify="center" style={{width: "90vw", marginLeft: "auto", marginRight: "auto"}}>
          <Grid item xs={12} sm={3}>
            <Button
              component={Link}
              to="/products"
              className={classes.btn}
              variant="contained"
              style={{ backgroundColor: colors.brown, color: "#ffffff" }}
            >
              Produits ajoutés
            </Button>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button
              component={Link}
              to="/addproduct"
              className={classes.btn}
              variant="contained"
              style={{
                backgroundColor: colors.white,
                border: "2px solid #DEC8A6",
              }}
            >
              Ajout d'un produit
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              component={Link}
              to="/statistics"
              className={classes.btn}
              variant="contained"
              style={{
                backgroundColor: colors.white,
                border: "2px solid #DEC8A6",
              }}
            >
              Statistiques
            </Button>
          </Grid>
          {/*<Grid item xs={12} sm={3}>
            <Button
              component={Link}
              to="/orders"
              className={classes.btn}
              variant="contained"
              style={{
                backgroundColor: colors.white,
                border: "2px solid #DEC8A6",
              }}
            >
              Commandes
            </Button>
          </Grid>*/}
        </Grid>

        <Box
          mt={5}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {dataLoaded ? (
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={"Produits"}
                data={data}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          ) : (
            <CircularProgress
              color={colors.brown}
              size={108}
              className={classes.spinner}
            />
          )}
        </Box>
      </div>
    </div>
  );
}

export default MyProductPage;
