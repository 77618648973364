import * as Yup from "yup";
import { fr } from "yup-locales";
import { setLocale } from "yup";
import { useState } from "react";
// import { useHistory } from "react-router-dom";

setLocale(fr);

export function useForgot() {
  const [pageId, setPageId] = useState(0); //numéro de page du formulaire d'inscription (0,1,2)
  const [alerPassowrd, setAlerPassowrd] = useState(""); //message d'alerte au niveau de l'adresse mail
  // const history = useHistory();

  const ForgotValues = {
    password: "",
    cfpassword: "",
  };

  // const lowercaseRegEx = /(?=.*[a-z])/;
  // const uppercaseRegEx = /(?=.*[A-Z])/;
  // const numericRegEx = /(?=.*[0-9])/;
  const passwordRegEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{4,}$/;

  let RegsiterSchema = Yup.object().shape({
  
    password: Yup.string()
      .min(
        4,
        "Le mot de passe est trop court - doit comporter au moins 4 caractères."
      )
      .matches(passwordRegEx, "Doit contenir des lettres minuscules !")
      // .matches(uppercaseRegEx, "Doit contenir des lettres majuscule !")
      // .matches(numericRegEx, "Doit contenir des chiffres !")
      //.matches(specialsRegEx, "Doit contenir un caractère spécial !")

      .required("Mot de passe requis"),
    cfpassword: Yup.string()
      .required("Veuillez confirmer le mot de passe")
      .oneOf([Yup.ref("password")], "Les mots de passe ne correspondent pas"),
  });

  const onSubmit = () => {
    // history.push("/signIn");

  };
  const onReturn = () => {
    setPageId(pageId - 1);
  };
  return {
    ForgotValues,
    RegsiterSchema,
    alerPassowrd,
    pageId,
    onReturn,
    onSubmit,
  };
}
