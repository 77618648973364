import React, { useEffect, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { colors } from "../../themes/colors";
import misterCoockyApi from "../../constante/apiUrl";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { CircularProgress } from "@material-ui/core";
import { compareAsc, format } from "date-fns";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useStyles } from "../../themes/Styles";
import ViewCarriers from "./ViewCarriers";
import AddCarriers from "./AddCarriers";

export default function CarriersTabs() {
  const [pageId, setPageId] = useState(0);
  const classes = useStyles();

  function getPageContent() {
    switch (pageId) {
      default:
      case 0:
        return <ViewCarriers></ViewCarriers>;
      case 1:
        return <AddCarriers></AddCarriers>;
    }
  }

  return (
    <div style={{ marginTop: 80 }}>
      <Container>
        <div
          className={classes.tabContainer}
          style={{ width: "45%", justifyContent: "space-around" }}
        >
          <Button
            className={classes.btn}
            variant="contained"
            style={
              pageId === 0
                ? {
                    backgroundColor: colors.brown,
                    color: "#ffffff",
                    border: "2px solid #DEC8A6",
                    width: "fit-content",
                    marginTop: 20,
                  }
                : {
                    backgroundColor: colors.white,
                    border: "2px solid #DEC8A6",
                    width: "fit-content",
                    marginTop: 20,
                  }
            }
            onClick={function (e) {
              setPageId(0);
            }}
          >
            Liste des transporteurs
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            style={
              pageId === 1
                ? {
                    backgroundColor: colors.brown,
                    color: "#ffffff",
                    border: "2px solid #DEC8A6",
                    width: "fit-content",
                    marginTop: 20,
                  }
                : {
                    backgroundColor: colors.white,
                    border: "2px solid #DEC8A6",
                    width: "fit-content",
                    marginTop: 20,
                  }
            }
            onClick={function (e) {
              setPageId(1);
            }}
          >
            Ajouter un transporteur
          </Button>
        </div>
        <Box>{getPageContent()}</Box>
      </Container>
    </div>
  );
}
