import { Field } from "formik";
import { useEffect, useState } from "react";
import ShowPasswordButton from "../../../../components/ShowPasswordButton";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { colors } from "../../../../themes/colors";
import { useStyles } from "./styles";

function Forgot2({ formik, alertMail }) {
  const classes = useStyles();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Box style={{ paddingLeft: 15, fontWeight: "700", marginBottom: -10 }}>
        Entrez votre nouveau mot de passe
      </Box>

      <Box className={classes.Container}>
        <Field
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          type={showPassword1 ? "text" : "password"}
          InputProps={{
            style: { height: 40 },
            endAdornment: (
              <ShowPasswordButton
                disabled={formik.isSubmitting}
                handleClickShowPassword={handleClickShowPassword1}
                handleMouseDownPassword={handleMouseDownPassword}
                showPassword={showPassword1}
              />
            ),
          }}
          name="password"
          label="Nouveau mot de passe"
          disabled={formik.isSubmitting ? true : false}
          className={classes.inputField}
          InputLabelProps={{
            margin: "dense",
          }}
          as={TextField}
          onInput={() => formik.setFieldTouched("password", true)}
          error={
            formik.errors.password && formik.touched.password ? true : false
          }
        />
        {formik.errors.password && formik.touched.password && (
          <Box className={classes.ErrorMes}>{formik.errors.password}</Box>
        )}
        <Field
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type={showPassword2 ? "text" : "password"}
          InputProps={{
            style: { height: 40 },
            endAdornment: (
              <ShowPasswordButton
                disabled={formik.isSubmitting}
                handleClickShowPassword={handleClickShowPassword2}
                handleMouseDownPassword={handleMouseDownPassword}
                showPassword={showPassword2}
              />
            ),
          }}
          name="cfpassword"
          id="cfpassword"
          label="Confirmer mot de passe"
          disabled={formik.isSubmitting ? true : false}
          className={classes.inputField}
          InputLabelProps={{
            margin: "dense",
          }}
          as={TextField}
          onInput={() => formik.setFieldTouched("cfpassword", true)}
          error={
            formik.errors.cfpassword && formik.touched.cfpassword ? true : false
          }
        />
        {formik.errors.cfpassword && formik.touched.cfpassword && (
          <Box className={classes.ErrorMes}>{formik.errors.cfpassword}</Box>
        )}
        <Box mt={10} className={classes.ContainerButtons}>
          <Button
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
            variant="contained"
            style={{
              backgroundColor: colors.brown,
              color: colors.white,
              width: "fit-content",
            }}
            className="default-btn"
            disabled={
              formik.isSubmitting ||
              formik.errors.password ||
              formik.errors.cfpassword ||
              formik.values.password.length === 0 ||
              formik.values.cfpassword.length === 0
                ? true
                : false
            }
          >
            Enregistrer
            {formik.isSubmitting && (
              <CircularProgress
                color={colors.white}
                size={18}
                style={{ marginLeft: 10 }}
              />
            )}
          </Button>
        </Box>

        {alertMail ? (
          <Alert severity="error" fullwidth>
            {alertMail}
          </Alert>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

export default Forgot2;
