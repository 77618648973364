/*eslint-disable*/
import { Paper } from "@material-ui/core";
import React from "react";
import { Form, Formik } from "formik";
import { useStyles } from "./styles";
import Forgot2 from "../Form2";
import { useHistory } from "react-router-dom";
import misterCoockyApi from "../../../../constante/apiUrl";
import { useForgot } from "../../Hooks/useForgot";
import { useParams,useLocation } from 'react-router-dom';
function FormForgot(props) {
  const { RegsiterSchema, ForgotValues, onReturn } = useForgot();
  const classes = useStyles();
  let location = useLocation();
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const key = searchParams.get("key");
  console.log(email,'Mo n email')

  console.log(location.pathname);
  return (
    <Paper className={classes.IndexPanier} elevation={0}>
      <Formik
        initialValues={ForgotValues}
        validationSchema={RegsiterSchema}
        onSubmit={(value, formikAction) => {
          setTimeout(() => {
            // fetchEmail(value.email)
             console.log(value)
            formikAction.setSubmitting(false);
            formikAction.resetForm();
            fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                query: `
                mutation ($forgotPassword: ForgotPasswordInput!) {
                  forgotPasswordFinish(forgotPassword: $forgotPassword) {
                      id,
                      user {
                          id,
                          name
                      },
                      organization {
                          id,
                          name
                      }
                  }
              }
                  `,
                variables: {
                  "forgotPassword":
                  {email: email,
                  resetKey:key,
                  password:value.password,
                  passwordConfirmation:value.cfpassword}
                },
              }),
            }).then(res=>{
              console.log(res,'ressss')
              history.push("/signIn");
            })
            //history.push("/signIn");
          }, 3000);
        }}
      >
        {(formik) => {
          return [
            <Form autoComplete="on" name="hotel">
              <Forgot2 formik={formik} onReturn={onReturn}  />
            </Form>,
          ];
        }}
      </Formik>
    </Paper>
  );
}

export default FormForgot;
