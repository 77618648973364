import React, { useEffect, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { colors } from "../../themes/colors";
import misterCoockyApi from "../../constante/apiUrl";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import GetAppIcon from "@material-ui/icons/GetApp";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LaunchIcon from "@material-ui/icons/Launch";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { CircularProgress } from "@material-ui/core";
import { compareAsc, format } from "date-fns";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useStyles } from "../../themes/Styles";
import Link from "@material-ui/core/Link";
import logoUps from "../../images/logo-ups.png";
import LinkUI from '@material-ui/core/Link';

export default function CarrierLayout({carrierLogo, carrierPricesLink}) {
  const [isAdding, setIsAdding] = useState(false);
  const classes = useStyles();
  return (
    <div
      style={{
        width: "60%",
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div className={classes.CarrierLayout}>
        <img
          src={carrierLogo}
          className={classes.CarrierLayoutLogo}
          alt="Logo du transporteur"
        ></img>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontSize: 16, fontWeight: 700, marginTop: 30 }}>
            Grille prix/délais/poids
          </span>
          <LinkUI
            onClick={event => window.open(
              carrierPricesLink
                )}
            style={{
              color: "#D94A46",
              textAlign: "center",
              marginTop: 10,
              marginBottom: 40,
              cursor: "pointer"
            }}
          >
            Voir la grille
            <LaunchIcon
              style={{
                fontSize: 18,
                marginLeft: 5,
                padding: 0,
                marginBottom: -5,
              }}
            ></LaunchIcon>
          </LinkUI>
{/*       <Button
            style={{
              border: "2px solid #C1BFC1",
            }}
          >
            Télécharger le fichier
          </Button> */}
        </div>
      </div>
{/*       <div style={{ display: "flex", flexDirection: "row" }}>
        <Checkbox style={{ marginTop: -10 }} color="#c0c0c0"></Checkbox>
        <span style={{ textAlign: "left" }}>
          Faire apparaître ce transporteur sur vos articles dans l'application
          Mister Coocky
        </span>
      </div>
      <Button
        className="default-btn"
        disabled={isAdding ? true : false}
        style={{
          width: 200,
          fontWeight: 900,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 20,
        }}
      >
        Ajouter
      </Button> */}
    </div>
  );
}
