import {Box, Container} from '@material-ui/core'
import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {CardComponent, Header} from '../components';
import mrAdoptCard from "../images/mr-adopt-card-resized.jpg"
import smrCard from "../images/smr-card-resized.jpg";
import goodiesCard from "../images/mr-goodies-card-resized.webp"
import misterCoockyApi from "../constante/apiUrl";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        
        color: theme.palette.text.secondary,
    },
    superContainer : {

    },
    cardContainer: {
        width: "94%",
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10vh",
        margin: "0 auto"
    },
    centerElements: {
        height: '90vh',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
    },
    cards: {
        height: 'fit-content', 
        width: "32%", 
        borderRadius: "10px",
        marginRight: "2vw"
    }
}));

/**
 * Page principale d'un utilisateur pro standard (pas admin)
 * @returns {*}
 * @constructor
 */
function Home() {
    const classes = useStyles();
    return (
        <div>
            <Header/>
                    <div className={classes.cardContainer}>
                        <Grid item className={classes.cards}>
                            <CardComponent path={"/products"} image={smrCard} title="Mr.Market" style={{height: 700}}
                                           description="Notre solution va vous aider à faire connaître vos offre de produits(Alimentation, Accessoire). Elle va vous permettre ainsi de mettre en avant leurs aspects Saint, Malin et/ou Responsable (SMR)."/>
                        </Grid>
{/*                         <Grid item className={classes.cards}>
                            <CardComponent path={"/products"} image={goodiesCard} title="Mr.Goodies" style={{height: 700}}
                                           description="Vous êtes un artisan ou un fournisseur de produits à destination des humains, mais toujours avec un lien au monde animal ? Mettez en avant vos produits grâce à la section Mr.Goodies"/>
                        </Grid> */}
                        <Grid item className={classes.cards}>
                            <CardComponent path={"/annonces"} image={mrAdoptCard} title="Mr.Adopt" style={{height: 'fit-content'}}
                                           description="Notre solution vous aidera à trouver un refuge à vos animaux simplement et rapidement. Vendre, donner et/ou échanger vos animaux se fait en 5 minutes grâce à une image !"/>
                        </Grid>
                    </div>

        </div>

    )
}

export default Home
