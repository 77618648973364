import React, { useEffect, useRef, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import { Header, Title } from "../components";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { colors } from "../themes/colors";
import GetAppIcon from "@material-ui/icons/GetApp";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { Link } from "react-router-dom";
import misterCoockyApi from "../constante/apiUrl";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import InputAdornment from "@material-ui/core/InputAdornment";
import Divider from "@material-ui/core/Divider";
import { CircularProgress } from "@material-ui/core";
import Question from "../images/Question.png";
import convert from 'convert-units';



const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      maxWidth: 50,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    btn: {
      padding: theme.spacing(2),
      color: "#000",
      width: "100%",
      fontWeight: "600",
      textTransform: "none",
      height: "fit-content",
      borderRadius: "10px",
      paddingTop: 5,
      paddingBottom: 5,
      "&:hover": {
        color: "red",
      },
    },
    btnContainer: {
      "&:hover": {
        color: "red",
      },
      [theme.breakpoints.between("xs", "md")]: {
        paddingTop: -20,
        paddingBottom: -20,
      },
    },
    btn2: {
      fontSize: "0.7em",
      marginTop: "10px",
      "&:hover": {
        color: "white",
      },
    },
    formControl: {
      width: "18vw",
      margin: "5px 0 5px 5px",
      [theme.breakpoints.between("xs", "md")]: {
        width: "75%",
      },
    },
    descriptionControl: {
      width: "18vw",
      height: 300,
      margin: "5px 0 5px 0",
    },
    item: {
      maxWidth: 400,
      padding: theme.spacing(2),
    },
    typotext: {
      fontSize: "1.2em",
      fontWeight: "bold",
      marginRight: "auto",
      marginLeft: "auto",
    },
    carriersContainer: {
      display: "flex", 
      flexDirection: "row", 
      width: "100%", 
      justifyContent:"center", 
      textAlign: "center", 
      alignItems:"center",
      marginTop: 20,
      [theme.breakpoints.between("xs", "md")]: {
        marginLeft: 10,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
    },
    priceContainer: {
      position: "relative",
      display: "flex", 
      flexDirection:"row", 
      width: "18vw", 
      alignItems: "center",
      marginLeft: 5,
      [theme.breakpoints.between("xs", "md")]: {
        width: "75%",
      },
    },
    productLink: {
      marginTop: 10, 
      width: "18vw" ,
      [theme.breakpoints.between("xs", "md")]: {
        width: "100%",
      },
    },
    rightGrid: {
      marginLeft: 12,
      width: "18vw",
      marginTop: 10,
      [theme.breakpoints.between("xs", "md")]: {
        width: "72%",
      },
    },
    productDescription: {
      marginTop: 5, 
      marginRight: 5, 
      width: "18vw",
      [theme.breakpoints.between("xs", "md")]: {
        width: "100%",
      },
    },
    productWeight: {
      marginTop: 10,
      width: "18vw",
      [theme.breakpoints.between("xs", "md")]: {
        width: "100%",
      },
    },
    productDimensionsContainer: {
      display: "flex", 
      flexDirection:"row", 
      width: "18vw", 
      alignItems: "center",
      [theme.breakpoints.between("xs", "md")]: {
        width: "100%",
      },
    },
    productDimension: {
      width: "33%",
      fontSize: 10,
    }

  })
);

/**
 * Composant de page d'ajout d'un produit
 * @param props
 * @returns {*}
 * @constructor
 */
function AddProduct(props) {
  const classes = useStyles();
  const [selectedItemToEdit, setSelectedItemToEdit] = useState(props.itemId);

  const [pageId, setPageId] = useState(0);
  const [prix, setPrix] = useState("");
  const [priceShown, setPriceShown] = useState("0.00 €")
  const [showPriceShown, setShowPriceShown] = useState(false)
  const [showPriceTip, setShowPriceTip] = useState(false)
  const [nomProduit, setNomProduit] = useState("");
  const [marque, setMarque] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [estSain, setEstSain] = useState(false);
  const [estMalin, setEstMalin] = useState(false);
  const [estResponsable, setEstResponsable] = useState(false);
  const [typeProduitId, setTypeProduitId] = useState("");
  const [typeProduitList, setTypeProduitList] = useState([]);
  const [lienProduit, setLienProduit] = useState("");
  const [unitPoids, setUnitPoids] = useState(convert().from('kg').possibilities().filter(unit => unit !== 'mt' && unit !== 't'))
  const [prevUnitPoidsSelect, setPrevUnitPoidsSelect] = useState("kg")
  const [unitPoidsSelect, setUnitPoidsSelect] = useState("kg")
  const [poids, setPoids] = useState("");
  const [unitDimensions, setUnitDimensions] = useState(convert().from('mm').possibilities())
  const [prevUnitDimensionsSelect, setPrevUnitDimensionsSelect] = useState("mm")
  const [unitDimensionsSelect, setUnitDimensionsSelect] = useState("mm")
  const [longueur, setLongueur] = useState("");
  const [largeur, setLargeur] = useState("");
  const [hauteur, setHauteur] = useState("");
  const [animalId, setAnimalId] = useState("");
  const [animalList, setAnimalList] = useState([]);
  const [fileTransmited, setFileTransmited] = useState(false);
  const [discount, setDiscount] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [sousCateroriesList, setSousCateroriesList] = useState([]);
  const [sousCategorieId, setSousCategorieId] = useState("");

  const [dataLoaded, setDataLoaded] = useState(false);
  const [isSavingItem, setIsSavingItem] = useState(false);

  const [carriersList, setCarriersList] = useState([]);
  const [carriersLoaded, setCarriersLoaded] = useState(false)
  const [productCarriers, setProductCarriers] = useState([]);
  const [editedProductCarriers, setEditedProductCarriers] = useState([]);

  const [noImageAlert, setNoImageAlert] = useState(false)

  


  /**
   * récupère l'item si en mode édition produit
   * et récupère la liste des animaux
   * et récupère les offres
   */
  useEffect(() => {
    if (selectedItemToEdit) {
      //si mode configuration
      fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
        //on récupère l'item
        method: "POST",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
                    query{​​​​​​​
                      item(id:"${selectedItemToEdit}"){​​​​​​​
                        id
                        ttcPrice
                        mark{
                            id
                            name
                        }
                        isHealthy
                        isSmart
                        isResponsible
                        discount
                        pet{
                            id
                            name
                        }
                        product{
                            id
                            name
                        }
                        offer{
                            id
                            name
                        }
                        comment
                        link
                        uri
                        weight
                        length
                        width
                        height
                        carriers{
                          id
                        }
                        category{
                            id
                            name
                        }
                        subCategories{
                            id
                            name
                        }
                      }​​​​​​​
                    }`,
        }),
      })
        .then((res) => res.json())
        .then(function (result) {
          setDescription(result.data.item.comment);
          setEstMalin(result.data.item.isSmart);
          setEstResponsable(result.data.item.isResponsible);
          setEstSain(result.data.item.isHealthy);
          setPrix(result.data.item.ttcPrice);
          setImage(result.data.item.uri);
          setMarque(result.data.item.mark.name);
          setDiscount(result.data.item.discount);
          setNomProduit(result.data.item.product.name);
          setLienProduit(result.data.item.link);
          setPoids(result.data.item.weight)
          setLongueur(result.data.item.length)
          setLargeur(result.data.item.width)
          setHauteur(result.data.item.height)
          setProductCarriers(result.data.item.carriers)
          setTypeProduitId(result.data.item.offer.id);
          setAnimalId(result.data.item.pet.id);
          if (result.data.item.subCategories[0] != null) {
            setSousCategorieId(result.data.item.subCategories[0].id);
          }
          generateCategories(
            result.data.item.pet.id,
            result.data.item.offer.id
          );
          onCategorySelected(
            result.data.item.pet.id,
            result.data.item.category.id
          );
          setPriceShown(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format((result.data.item.ttcPrice - (result.data.item.ttcPrice * result.data.item.discount) / 100)))
        });
    }
    //récupérations de la liste des animaux
    fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
                    query{​​​​​​​
                        pets{​​​​​​​
                            id
                            name
                        }​​​​​​​
                    }`,
      }),
    })
      .then((res) => res.json())
      .then(function (result) {
        setAnimalList(result.data.pets);
      });

    fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
                    query{​​​​​​​
                        getOffers{​​​​​​​
                            id
                            name
                        }​​​​​​​
                    }`,
      }),
    })
      .then((res) => res.json())
      .then(function (result) {
        setTypeProduitList(result.data.getOffers);
        setDataLoaded(true);
      });

      fetch(`${misterCoockyApi.misterCoockyApi}/api/carriers/user-pro/`+localStorage.getItem("userId"), {
        method: "GET",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(function (result) {
          setCarriersList(result);
        })
        .then(()=>{
          setCarriersLoaded(true)
        });
  }, []);

  /**
   * Lors de l'import d'un fichier
   * @param event
   */
  function handleFile(event) {
    const file = event.target.files[0];
    let data = new FormData();
    data.append("file", file);
    const userId = localStorage.getItem("userId");
    fetch(
      `${misterCoockyApi.misterCoockyApi}/uploadFile?sectionName=smr&userId=${userId}`,
      {
        method: "POST",
        body: data,
      }
    )
      .then((res) => res.json())
      .then(function (result) {
        setFileTransmited(true);
      })
      .catch(function (error) {
        console.log("Erreur pendant l'upload : " + error.message);
      });
  }

  /**
   * Lors de la sélection d'un animal
   * @param animalId
   */
  function handleAnimalChange(animalId) {
    setAnimalId(animalId);
    setTypeProduitId("");
    setCategoryId("")
    setSousCategorieId("")
  }

  /**
   * Lors de la sélection d'un type
   * @param type
   */
  function handleTypeChange(type) {
    setTypeProduitId(type);
    generateCategories(animalId, type);
  }

  /**
   * récupération des catégories
   * @param animal
   * @param offer
   */
  function generateCategories(animal, offer) {
    setCategoryList([]);
    setSousCateroriesList([]);
    if (animal && offer) {
      fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        body: JSON.stringify({
          query: `
                    query{​​​​​​​
                         getCategoriesByPetIdAndOfferId(petId:"${animal}", offerId:"${offer}"){
                            id
                            name
                        }​​​​​​​    
                    }`,
        }),
      })
        .then((res) => res.json())
        .then(function (result) {
          setCategoryList(result.data.getCategoriesByPetIdAndOfferId);
          console.log(result.data.getCategoriesByPetIdAndOfferId);
        });
    }
  }

  /**
   * Lors de la sélection d'une catégorie
   * @param pet
   * @param category
   */
  function onCategorySelected(pet, category) {
    setSousCateroriesList([]);
    setCategoryId(category);
    fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
                    query{​​​​​​​
                        getSubCategoriesByPetAndCategoryIds(petId:"${pet}", categoryId:"${category}"){
                            id
                            name
                        }​​​​​​​
                    }`,
      }),
    })
      .then((res) => res.json())
      .then(function (result) {
        setSousCateroriesList(
          arrUnique(result.data.getSubCategoriesByPetAndCategoryIds)
        );
      });
  }

  /**
   * fonction permettant de supprimer les doublons d'une liste
   * @param array
   * @returns {*}
   */
  function arrUnique(array) {
    var clean = array.filter(
      (arr, index, self) => index === self.findIndex((t) => t.name === arr.name)
    );
    return clean;
  }

  /**
   * Lors de l'ajout d'une image / mise en base64
   * @param file
   */
  function imageUploaded(file) {
    let base64String = "";
    let reader = new FileReader();
    reader.onload = function () {
      base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
      setImage(base64String);
      setNoImageAlert(false);

    };
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    setEditedProductCarriers(() => [...productCarriers.map(item => item.id)])

   }, [productCarriers])

  /**
   * Prévisiualisation de l'image impotée
   * @param e
   */
  function handleImagePreview(e) {
    if (e.target.files[0]) {
      imageUploaded(e.target.files[0]);
    }
  }



  useEffect(() => {
    const convertedPoids = convert(poids).from(unitPoidsSelect).to('kg');
    setPoids(convertedPoids);
  }, []);

  useEffect(() => {
    const convertedLongueur = convert(longueur).from(unitDimensionsSelect).to('mm');
    setLongueur(convertedLongueur);
    const convertedLargeur = convert(largeur).from(unitDimensionsSelect).to('mm');
    setLargeur(convertedLargeur);
    const convertedHauteur = convert(hauteur).from(unitDimensionsSelect).to('mm');
    setHauteur(convertedHauteur);
  }, []);

  const handlePoidsUnitChange = (event) => {
    const newPoidsUnit = event.target.value;
    const parsedPoids = parseNumberWithCommas(poids)
    const convertedPoids = convert(parsedPoids).from(prevUnitPoidsSelect).to(newPoidsUnit);
    setPoids(convertedPoids);
    setPrevUnitPoidsSelect(newPoidsUnit);
    setUnitPoidsSelect(newPoidsUnit);
  };

  const handleDimensionsUnitChange = (event) => {
    const newDimensionUnit = event.target.value;
    const convertedLongueur = convert(longueur).from(prevUnitDimensionsSelect).to(newDimensionUnit);
    setLongueur(convertedLongueur);
    const convertedLargeur = convert(largeur).from(prevUnitDimensionsSelect).to(newDimensionUnit);
    setLargeur(convertedLargeur);
    const convertedHauteur = convert(hauteur).from(prevUnitDimensionsSelect).to(newDimensionUnit);
    setHauteur(convertedHauteur);
    setPrevUnitDimensionsSelect(newDimensionUnit);
    setUnitDimensionsSelect(newDimensionUnit);
  };



  function handleAddingProductCarriers(carrier) {
    setEditedProductCarriers(editedProductCarriers => [...editedProductCarriers, carrier])
  }

  function handleRemovingProductCarriers(carrier) {
    setEditedProductCarriers(editedProductCarriers => [...editedProductCarriers.filter(item => item !== carrier)]);
  }



  /**
   * Lors de l'envoi du formulaire de création
   * @param e
   */
  function handleSubmit(e) {
    e.preventDefault()
    let tmpDiscount = discount;
    if (discount === "") {
      tmpDiscount = 0;
    }
    if (selectedItemToEdit) {
      //si mode d'édition
      setIsSavingItem(true)
      const parsedWeight = parseNumberWithCommas(poids)
      const parsedLength = parseNumberWithCommas(longueur)
      const parsedWidth = parseNumberWithCommas(largeur)
      const parsedHeight = parseNumberWithCommas(hauteur)
      fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
        method: "POST",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
                        mutation updateItem($input: ItemInput){
                            updateItem(id: "${selectedItemToEdit}", item: $input) {​​​​​​
                              id
                          }
                        }`,
          variables: {
            input: {
              price: parseNumberWithCommas(prix),
              comment: description,
              isHealthy: Boolean(estSain),
              isSmart: Boolean(estMalin),
              isResponsible: Boolean(estResponsable),
              link: lienProduit,
              uri: image,
              weight:  convert(parsedWeight).from(unitPoidsSelect).to("kg"),
              length:  convert(parsedLength).from(unitDimensionsSelect).to("mm"),
              width:  convert(parsedWidth).from(unitDimensionsSelect).to("mm"),
              height:  convert(parsedHeight).from(unitDimensionsSelect).to("mm"),
              carrierIds : editedProductCarriers,
              supplierId: localStorage.getItem("supplierId"),
              discount: tmpDiscount,
              productName: nomProduit,
              markName: marque,
              petId: animalId,
              offerId: typeProduitId,
              categoryId: categoryId,
              subCategoryId: sousCategorieId,
              
            },
          },
        }),
      })
        .then((res) => res.json())
        .then(function (result) {
          if (result.data.updateItem.id !== null) {
            window.location.href = "/products";
            setIsSavingItem(false)
          }
        });
    } else {
      let tmpDiscount = discount;
      if (discount === "") {
        tmpDiscount = 0;
      }
      const parsedWeight = parseNumberWithCommas(poids)
      const parsedLength = parseNumberWithCommas(longueur)
      const parsedWidth = parseNumberWithCommas(largeur)
      const parsedHeight = parseNumberWithCommas(hauteur)

      setIsSavingItem(true)
      fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
        method: "POST",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
                        mutation CreateItem($input: ItemInput){
                            createItem(item: $input) {​​​​​​
                            id
                          }​​​​​​​
                        }`,
          variables: {
            input: {
              price: parseNumberWithCommas(prix),
              comment: description,
              isHealthy: estSain,
              isSmart: estMalin,
              isResponsible: estResponsable,
              link: lienProduit,
              uri: image,
              weight:  convert(parsedWeight).from(unitPoidsSelect).to("kg"),
              length:  convert(parsedLength).from(unitDimensionsSelect).to("mm"),
              width:  convert(parsedWidth).from(unitDimensionsSelect).to("mm"),
              height:  convert(parsedHeight).from(unitDimensionsSelect).to("mm"),
              carrierIds : editedProductCarriers,
              userId: localStorage.getItem("userId"),
              productName: nomProduit,
              discount: tmpDiscount,
              markName: marque,
              supplierId: localStorage.getItem("supplierId"),
              petId: animalId,
              offerId: typeProduitId,
              categoryId: categoryId,
              subCategoryId: sousCategorieId,
              organizationId: localStorage.getItem("organizationId"),
            },
          },
        }),
      })
        .then((res) => res.json())
        .then(function (result) {
          if (result.data.createItem.id !== null) {
            window.location.href = "/products";
            setIsSavingItem(false)
          }
        });
    }
  }

  function parseNumberWithCommas(input) {
      const stringInput = input.toString()
      const replaced = stringInput.replace(/,/g, '.');
      const number = parseFloat(replaced);
      return number;
    
  }

  function handlePriceChanges(value) {
    setPrix(value);
    const parsedNumber = parseNumberWithCommas(value);
    handleShownPrice(parsedNumber, discount)
  }

  function handleShownPrice(price, discount) {
    setPriceShown(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format((Number(price) - (Number(price) * Number(discount)) / 100)))
  }
  const NUMBER_ALLOWED_CHARS_REGEXP = /[0-9\.\,]+/;
  const handleKeyPress = (event) => {
    if (!NUMBER_ALLOWED_CHARS_REGEXP.test(event.key)) {
      event.preventDefault();
    }
  };
  /**
   * Renvoi du contenu de la page
   * @returns {*}
   */
  function getAddProductContent() {
    const dataImg = "data:image/png;base64," + image;
    switch (pageId) {
      case 0:
        return (
      <div>

        <div style={{marginBottom: -50}}>
          <span style={{color: "#D94A46", marginRight: 6, fontSize: 18}}>*</span>
          <span style={{color: "rgba(0, 0, 0, 0.54)"}}>Champs requis</span>
        </div>
          <Box mt={10} style={{ display: "flex", justifyContent: "center", width: "90vw" }}>

            {selectedItemToEdit ? (
              <h2 style={{ color: colors.brown, marginRight: 50 }}>Mode édition de produit </h2>
            ) : 
              null
            }
            {dataLoaded ? (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item align={"left"} sm={12} md={6} lg={3} style={{display: "flex", flexDirection: "column"}}>
                      <FormLabel style={{marginBottom: 20 }} component="legend">Image du produit</FormLabel>
                      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        {image ? <img src={dataImg} width={200} /> : null}
                        <Button
                          variant="outlined"
                          style={{
                            width: "fit-content",
                            maxWidth: "100%",
                            border: "2px solid #C1BFC1",
                            height: "fit-content",
                            marginTop: 20,
                          }}
                          component="label"
                          size={"small"}
                          required={!image}
                          disabled={isSavingItem}
                        >
                          {image ? "Remplacer l'image" : "Ajouter une image"}
                          <input
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={handleImagePreview}
                            required={!image}
                          />
                        </Button>
                        {noImageAlert ? <span style={{color: "red"}}>Aucune image ajoutée !</span> : null}

                      </div>

                    </Grid>
                    <Grid item sm={12} md={6} lg={3}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        required={true}
                        spacing={3}
                      >
                        <InputLabel id="select-animal">Animal</InputLabel>
                        <Select
                          labelId="select-animal"
                          id="select-animal"
                          disabled={isSavingItem}
                          value={animalId}
                          onChange={(event) => {
                            handleAnimalChange(event.target.value);
                          }}
                          label="Animal"
                        >
                          {animalList ? (
                            animalList.map((array) => {
                              return (
                                <MenuItem key={array.id} value={array.id}>
                                  {array.name}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Select>
                      </FormControl>
                      <TextField
                        required
                        className={classes.formControl}
                        id="idnNom"
                        label="Nom du produit"
                        disabled={isSavingItem}
                        value={nomProduit}
                        onChange={(e) => setNomProduit(e.target.value)}
                        variant="outlined"
                      />
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                      >
                        <FormLabel style={{marginBottom:5}} component="legend">Critères SMR</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            style={{marginBottom:-10, color: "rgba(0, 0, 0, 0.54)"}}
                            control={
                              <Checkbox
                                checked={estSain}
                                disabled={isSavingItem}
                                color={"default"}
                                onChange={(e) => setEstSain(e.target.checked)}
                                name="Sain"
                              />
                            }
                            label="Sain"
                          />
                          <FormControlLabel
                            style={{marginBottom:-10, color: "rgba(0, 0, 0, 0.54)"}}
                            control={
                              <Checkbox
                                checked={estMalin}
                                disabled={isSavingItem}
                                color={"default"}
                                onChange={(e) => setEstMalin(e.target.checked)}
                                name="Malin"
                              />
                            }
                            label="Malin"
                          />
                          <FormControlLabel
                            style={{color: "rgba(0, 0, 0, 0.54)"}}
                            control={
                              <Checkbox
                                checked={estResponsable}
                                disabled={isSavingItem}
                                color={"default"}
                                onChange={(e) =>
                                  setEstResponsable(e.target.checked)
                                }
                                name="Responsable"
                              />
                            }
                            label="Responsable"
                          />
                          
                        </FormGroup>
                        
                      </FormControl>
                      <div className={classes.priceContainer} >
                        <TextField
                              required
                              id="idPrix"
                              label="Prix"
                              disabled={isSavingItem}
                              style={{ width: 120, marginRight: 10 }}
                              onKeyPress={handleKeyPress}
                              onMouseEnter={() =>
                                setShowPriceShown(true)}
                              onMouseLeave={() =>
                                setShowPriceShown(false)}
                              onTouchStart={() =>
                                setShowPriceShown(true)}
                              onBlur={() =>
                                setShowPriceShown(false)}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  max: 99,
                                  step: "0.1",
                                },
                                endAdornment: (
                                  <InputAdornment position="end">€</InputAdornment>
                                ),
                              }}
                              value={prix}
                              onChange={(e) => handlePriceChanges(e.target.value)}
                              variant="outlined"
                            />                          

                            <TextField
                              id="idnNom"
                              label="Discount(%)"
                              value={discount}
                              style={{ width: 120}}
                              disabled={isSavingItem}
                              onKeyPress={handleKeyPress}
                              onMouseEnter={() =>
                                setShowPriceShown(true)}
                              onMouseLeave={() =>
                                setShowPriceShown(false)}
                              onTouchStart={() =>
                                setShowPriceShown(true)}
                              onBlur={() =>
                                setShowPriceShown(false)}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  max: 99,
                                  step: "0.1",
                                  maxLength: 5,
                                },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    -
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              placeholder={"0"}
                              onChange={(e) => 
                                {
                                  if (e.target.value <= 100) {
                                    setDiscount(e.target.value)
                                    handleShownPrice(parseNumberWithCommas(prix), e.target.value)
                                  }
                                }
                                }
                              variant="outlined"
                            />
                            {showPriceShown ? 
                                  <div style={{position: "absolute", 
                                     width: "fit-content", 
                                     height: "fit-content", 
                                     top: "100%",
                                     marginTop: 10,
                                     padding: 10, 
                                     border: "2px solid #DEC8A6",
                                     borderRadius: 10,
                                     backgroundColor: "#FFFFFF",
                                     zIndex: 1000
                                     }}> Prix affiché : <span style={{color: "#D94A46"}}>{priceShown}</span> 
                                  </div>
                            : null}
                                {showPriceTip ? <span style={{position: "absolute", 
                                            width: "18vw", 
                                            height: "fit-content", 
                                            left: "100%",
                                            marginTop: 10,
                                            padding: 10, 
                                            border: "2px solid #DEC8A6",
                                            borderRadius: 10,
                                            backgroundColor: "#FFFFFF",
                                            zIndex: 1000
                                            }}>
                                Passez le curseur sur le prix ou le discount pour voir le prix qui sera affiché sur l'appli mobile</span>
                                : null}
                               <img src={Question} width={35} height={30}
                                onMouseEnter={() =>
                                  setShowPriceTip(true)}
                                onMouseLeave={() =>
                                  setShowPriceTip(false)}
                                onTouchStart={() =>
                                  setShowPriceTip(!showPriceTip)}
                               />                 
                      </div>


                    </Grid>
                    <Grid item sm={12} md={6} lg={3}>
                      <TextField
                        required
                        className={classes.formControl}
                        id="idMarque"
                        label="Marque"
                        disabled={isSavingItem}
                        value={marque}
                        onChange={(e) => setMarque(e.target.value)}
                        variant="outlined"
                      />
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        required={true}
                      >
                        <InputLabel id="select-typeProduit">
                          Type de produit
                        </InputLabel>
                        <Select
                          labelId="select-typeProduit"
                          id="select-typeProduit"
                          disabled={isSavingItem}
                          value={typeProduitId}
                          onChange={(e) => handleTypeChange(e.target.value)}
                          label="Type de produit"
                        >
                          {typeProduitList ? (
                            typeProduitList.map(function (type) {
                              return (
                                <MenuItem key={type.id} value={type.id}>
                                  {type.name}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Select>
                      </FormControl>
                      <FormControl  
                        variant="outlined"
                        className={classes.formControl}
                        required
                      >
                        <InputLabel id="select-categorie">Catégorie</InputLabel>
                        <Select
                          labelId="select-categorie"
                          id="select-categorie"
                          value={categoryId}
                          disabled={
                           isSavingItem || animalId === "" || typeProduitId === ""
                              ? true
                              : false
                          }
                          onChange={function (event) {
                            onCategorySelected(animalId, event.target.value);
                          }}
                          label="Catégorie "
                        >
                          {categoryList ? (
                            categoryList.map(function (cat) {
                              if (cat) {
                                return (
                                  <MenuItem key={cat.id} value={cat.id}>
                                    {cat.name}
                                  </MenuItem>
                                );
                              }
                            })
                          ) : (
                            <></>
                          )}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="select-sousCategorie">
                          Sous catégorie
                        </InputLabel>
                        <Select
                          labelId="select-sousCategorie"
                          id="select-sousCategorie"
                          value={sousCategorieId}
                          disabled={
                            isSavingItem ||
                            animalId === "" ||
                            typeProduitId === "" ||
                            categoryId === ""
                              ? true
                              : false
                          }
                          onChange={(e) => setSousCategorieId(e.target.value)}
                          label="Sous Catégorie"
                        >
                          {sousCateroriesList ? (
                            sousCateroriesList.map(function (sousCat) {
                              return (
                                <MenuItem key={sousCat.id} value={sousCat.id}>
                                  {sousCat.name}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </Select>
                        <TextField
                        required
                        className={classes.productLink}
                        id="idLien"
                        label="Lien du produit"
                        disabled={isSavingItem}
                        value={lienProduit}
                        variant="outlined"
                        onChange={(e) => setLienProduit(e.target.value)}
                      />
                      </FormControl>
                    </Grid>
                    <Grid  className={classes.rightGrid}>
                      <TextField
                        className={classes.productDescription}
                        id="description"
                        label="Description"
                        disabled={isSavingItem}
                        multiline
                        minRows={4}
                        maxRows={5}
                        InputLabelProps={{
                          margin: "dense",
                        }}
                        defaultValue=""
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.productWeight}
                        id="poids"
                        label="Poids article"
                        disabled={isSavingItem}
                        value={poids}
                        variant="outlined"
                        required
                        onChange={(e) => 
                          {
                              setPoids(e.target.value)
                          }}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: 99,
                            step: "0.1",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                                <Select
                                  labelId="select-unit-poids"
                                  variant="outlined"
                                  id="select-unit-poids"
                                  className="custom-select"
                                  disabled={isSavingItem}
                                  style={{height: "fit-content", paddingLeft: 0, paddingRight: 0}}
                                  value={unitPoidsSelect}
                                  onChange={function (event) {
                                    handlePoidsUnitChange(event)
                                  }}
                                >
                                    {unitPoids.map(function (unp) {
                                        return (
                                          <MenuItem key={unp} value={unp}>
                                            <span style={{fontSize: 14, color: "rgba(0, 0, 0, 0.54)", marginRight: -6}}>{unp}</span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                </Select>
                                </InputAdornment>
                          ),
                        }}
                      />
                      <FormLabel style={{marginTop: 10, marginBottom: 10}} 
                      required
                      component="legend">Dimensions article
                                <Select
                                  labelId="select-unit-dimensions"
                                  variant="outlined"
                                  id="select-unit-dimensions"
                                  disabled={isSavingItem}
                                  style={{height: "fit-content", paddingLeft: 0, paddingRight: 0, marginLeft: 10}}
                                  className="custom-select"
                                  value={unitDimensionsSelect}
                                  onChange={function (event) {
                                    handleDimensionsUnitChange(event)
                                  }}
                                >
                                    {unitDimensions.map(function (und) {
                                        return (
                                          <MenuItem key={und} value={und}>
                                            <span style={{fontSize: 14, color: "rgba(0, 0, 0, 0.54)", marginRight: -6}}>{und}</span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                </Select>
                      </FormLabel>
                      <div className={classes.productDimensionsContainer}>
                        <TextField
                          className={classes.productDimension}
                          id="longueur"
                          label="L"
                          disabled={isSavingItem}
                          value={longueur}
                          variant="outlined"
                          required
                          style={{fontSize: 10}}
                          onChange={(e) => 
                            {
                              setLongueur(e.target.value)
                            }}
                          onKeyPress={handleKeyPress}
                          InputProps={{
                            style: {
                              fontSize: 11,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                  <span style={{fontSize: 10, color: "rgba(0, 0, 0, 0.54)", marginRight: -8}}>{unitDimensionsSelect}</span>
                              </InputAdornment>
                            ),
                          }}
                        />                       
                          <span style={{fontSize: 10, color: "rgba(0, 0, 0, 0.54)", marginLeft:5, marginRight:5}}>x</span>
                        <TextField
                          className={classes.productDimension}
                          id="largeur"
                          label="l"
                          disabled={isSavingItem}
                          value={largeur}
                          variant="outlined"
                          required
                          onChange={(e) => 
                            {
                              setLargeur(e.target.value)
                            }}
                          onKeyPress={handleKeyPress}
                          InputProps={{
                            style: {
                              fontSize: 11,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                  <span style={{fontSize: 10, color: "rgba(0, 0, 0, 0.54)", marginRight: -8}}>{unitDimensionsSelect}</span>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <span style={{fontSize: 10, color: "rgba(0, 0, 0, 0.54)", marginLeft:5, marginRight:5}}>x</span>
                        <TextField
                          className={classes.productDimension}
                          id="hauteur"
                          label="H"
                          disabled={isSavingItem}
                          value={hauteur}
                          variant="outlined"
                          required
                          onChange={(e) => 
                            {
                              setHauteur(e.target.value)
                            }}
                          onKeyPress={handleKeyPress}
                          InputProps={{
                            style: {
                              fontSize: 11,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                  <span style={{fontSize: 10, color: "rgba(0, 0, 0, 0.54)", marginRight: -8}}>{unitDimensionsSelect}</span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <div className={classes.carriersContainer}>
                    <FormLabel style={{marginTop: 10, marginBottom: 10, marginRight: 25}} 
                    required 
                    component="legend">Transporteurs</FormLabel>
                    {carriersList.length > 0 ? 
                                    carriersList.map((array) => {
                                      return (
                                        <FormControlLabel
                                        required
                                        style={{marginBottom:-10, color: "rgba(0, 0, 0, 0.54)"}}
                                        key={array.carrier_id}
                                        control={
                                          <Checkbox
                                            disabled={isSavingItem}
                                            color={"default"}                          
                                            checked={editedProductCarriers.includes(array.carrier_id)}
                                            name={array.carrierFriendlyName}
                                            key={array.carrier_id}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                handleAddingProductCarriers(array.carrier_id);
                                              }
                                              else if (!e.target.checked) {
                                                handleRemovingProductCarriers(array.carrier_id)
                                              }
                                            } }
                                          />
                                        }
                                        label={array.carrierFriendlyName}
                                      />
                                      );
                                    })
                    : 
                    !carriersLoaded ? <CircularProgress color={colors.brown} size={30} /> 
                    :
                    <span>Aucun transporteur renseigné, ajoutez-les dans votre profil dans l’onglet mes transporteurs <a href="/userProfile" style={{color: "#D94A46"}}>du profil</a>.</span>}
                    
                  </div>



                  <Grid container item xs={12} spacing={1} style={{justifyContent: "flex-end"}}>
                      <Button
                        type="submit"
                        variant="contained"
                        className="default-btn"
                        style={{marginTop: 40, marginRight: 65}}
                        disabled={isSavingItem}
                        onClick={() => {
                          if (image === "") {
                            setNoImageAlert(true)
                          }
                        }}

                      >
                        Valider
                        {isSavingItem ? <CircularProgress color={colors.brown} size={14} style={{marginLeft: 5}} /> : null}
                        
                      </Button>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <CircularProgress color={colors.brown} size={108} />
            )}
          </Box>
          </div>

        );
      case 1:
        return (
          <Grid
            container
            spacing={3}
            direction={"column"}
            align={"center"}
            className={classes.root}
          >
            <Grid className={classes.paper}>
              <Typography className={classes.typotext}>
                Vous avez beaucoup de produits à mettre en vente ?
              </Typography>
              <Typography>
                Téléchargez notre template, remplissez-le en entier et vos
                produits seront mis en ligne.
              </Typography>
              <Grid container spacing={2} className={classes.item}>
                <Grid item xs zeroMinWidth>
                  <Typography className={classes.typotext}>
                    1. Télécharger le template
                  </Typography>
                  <Button
                    href={"files/formulaire_produits.xlsx"}
                    style={{
                      width: "fit-content",
                      border: "2px solid #C1BFC1",
                      backgroundColor: "#FFFFFF",
                      marginTop: 20,
                    }}
                    download
                    target="_blank"
                  >
                    Télécharger le template
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.paper}>
              <Grid container spacing={2} className={classes.item}>
                <Grid item xs zeroMinWidth>
                  <span className={classes.typotext}>
                    2. Remplir le document Excel avec vos différents produits
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.paper}>
              <Grid container spacing={2} className={classes.item}>
                <Grid item xs zeroMinWidth>
                  <Typography className={classes.typotext}>
                    3. Importer votre fichier .xlsx
                  </Typography>
                  <Button
                    style={{
                      width: "fit-content",
                      border: "2px solid #C1BFC1",
                      backgroundColor: "#FFFFFF",
                      marginTop: 20,
                    }}
                    component="label"
                  >
                    Déposer le template{" "}
                    <input
                      accept=".xlsx, .numbers, .csv"
                      type="file"
                      onChange={(e) => handleFile(e)}
                      hidden
                    />{" "}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {
              <Snackbar
                open={fileTransmited}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setFileTransmited(false)}
              >
                <Alert severity="success">
                  Fichier transmis, il sera bientôt traîté
                </Alert>
              </Snackbar>
            }
          </Grid>
        );
      case 2:
        return (
          <Grid className={classes.paper} align={"center"}>
            <Grid container spacing={2} className={classes.item}>
              <Typography className={classes.typotext}>
                3. Importer votre fichier .xlsx
              </Typography>
              <Button
                style={{
                  width: "fit-content",
                  border: "2px solid #C1BFC1",
                  backgroundColor: "#FFFFFF",
                  marginTop: 20,
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
                variant="contained"
                component="label"
              >
                Déposer le template{" "}
                <input
                  accept=".xlsx"
                  type="file"
                  onChange={(e) => handleFile(e)}
                  hidden
                />{" "}
              </Button>
            </Grid>
            {
              <Snackbar
                open={fileTransmited}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setFileTransmited(false)}
              >
                <Alert severity="success">
                  Fichier transmis, il sera bientôt traîté
                </Alert>
              </Snackbar>
            }
          </Grid>
        );
      default:
        break;
    }
  }

  return (
    <div>
      <Header />
      <div style={{width: "90vw", marginLeft: "auto", marginRight: "auto"}}>
        <Title name={"Mr.Market — Ajouter des Produits"} />
        <Grid container spacing={5} justify="center" style={{width: "90vw", marginLeft: "auto", marginRight: "auto"}}>
          <Grid className={classes.btnContainer} item xs={12} sm={3}>
            <Button
              component={Link}
              to="/products"
              className={classes.btn}
              variant="contained"
              style={{
                backgroundColor: colors.white,
                border: "2px solid #DEC8A6",
              }}
            >
              Produits ajoutés
            </Button>
          </Grid>

          <Grid className={classes.btnContainer} item xs={12} sm={3}>
            <Button
              component={Link}
              to="/addproduct"
              className={classes.btn}
              variant="contained"
              style={{
                backgroundColor: colors.brown,
                color: "#ffffff",
                border: "2px solid #DEC8A6",
              }}
            >
              Ajout d'un produit
            </Button>
          </Grid>
          <Grid className={classes.btnContainer} item xs={12} sm={3}>
            <Button
              component={Link}
              to="/statistics"
              className={classes.btn}
              variant="contained"
              style={{
                backgroundColor: colors.white,
                border: "2px solid #DEC8A6",
              }}
            >
              Statistiques
            </Button>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: 20, marginBottom: 5 }} />
        <Divider style={{ marginBottom: 20 }} />
        <Grid container spacing={5} justify="center">
          <Grid className={classes.btnContainer} item xs={12} sm={3}>
            <Button
              className={classes.btn}
              variant="contained"
              style={
                pageId === 0
                  ? {
                      backgroundColor: colors.brown,
                      color: "#ffffff",
                      border: "2px solid #DEC8A6",
                    }
                  : {
                      backgroundColor: colors.white,
                      border: "2px solid #DEC8A6",
                    }
              }
              onClick={(e) => setPageId(0)}
            >
              Remplir un formulaire
            </Button>
          </Grid>
          <Grid className={classes.btnContainer} item xs={12} sm={3}>
            <Button
              className={classes.btn}
              variant="contained"
              style={
                pageId === 1
                  ? {
                      backgroundColor: colors.brown,
                      color: "#ffffff",
                      border: "2px solid #DEC8A6",
                    }
                  : {
                      backgroundColor: colors.white,
                      border: "2px solid #DEC8A6",
                    }
              }
              startIcon={<GetAppIcon />}
              onClick={(e) => setPageId(1)}
            >
              Remplir un template
            </Button>
          </Grid>
          <Grid className={classes.btnContainer} item xs={12} sm={3}>
            <Button
              className={classes.btn}
              variant="contained"
              style={
                pageId === 2
                  ? {
                      backgroundColor: colors.brown,
                      color: "#ffffff",
                      border: "2px solid #DEC8A6",
                    }
                  : {
                      backgroundColor: colors.white,
                      border: "2px solid #DEC8A6",
                    }
              }
              startIcon={<GetAppIcon />}
              onClick={(e) => setPageId(2)}
            >
              Importer un fichier
            </Button>
          </Grid>
        </Grid>
        <Box mt={5}>{getAddProductContent()}</Box>
      </div>
    </div>
  );
}

export default AddProduct;
