import React, { useEffect, useState, useRef } from "react";
import MUIDataTable from "mui-datatables";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from '@material-ui/core/Divider';
import { colors } from "../../themes/colors";
import misterCoockyApi from "../../constante/apiUrl";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";
import Modal from 'react-modal';
import { CircularProgress } from '@material-ui/core'
import { compareAsc, format } from 'date-fns'
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useStyles } from "../UserProfile";
import Link from "@material-ui/core/Link";
import {createMuiTheme, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material/styles';


const   getMuiTheme = () =>
createTheme({
  components: {
    MUIDataTable: {
      styleOverrides: {
        root: {
          width: "100vw",
        },
        Paper: {
          padding: 10
        }
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          padding: 10
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        filterPaper: {
          width: "30vw"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: "5px solid rgba(0, 0, 0, 0.1)",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          zIndex: "0",
          fontSize: 14,    
        },
        root: {
          fontSize: 14,
          textAlign: "center",
          borderBottom: "3px solid rgba(0, 0, 0, 0.1)",
        }
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
          textTransform: "none",
          alignItems: "center"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          fontSize: 14,
          textTransform: "none",
          alignItems: "center"
        }
      }
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          '& .MuiToolbar-root': {
            backgroundColor: 'white',
          },
        },
      },
    },
  },
});

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      transform: "translate(-50%, -50%)",
      zIndex: "999",
    },
  };

export default function ViewCarriers() {
    const [carriersList, setCarriersList] = useState([]);
    const [carriersLoaded, setCarriersLoaded] = useState(false)
    const [selectedCarrierToDelete, setSelectedCarrierToDelete] = useState(null); 
    const [isDeletingCarrier, setIsDeletingCarrier] = useState(false)

    useEffect(() => {
        refreshList()
        },[])

        const refreshList = () => {
            fetch(`${misterCoockyApi.misterCoockyApi}/api/carriers/user-pro/`+localStorage.getItem("userId"), {
                method: "GET",
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
                  "Content-Type": "application/json",
                },
              })
                .then((res) => res.json())
                .then(function (result) {
                  console.log("my carriers : "+ JSON.stringify(result));
                  arrangeParameters(result)
                })
                .then(()=>{
                  setCarriersLoaded(true)
                });
        }

        function arrangeParameters(arr) {
            var carriers = [];
            arr.forEach((i) => {
              carriers.push({
                id: i.carrier_id,
                logo: i.carrierLogo,
                carrierName: i.carrierNickname,
                rates: i.carrierPriceListLink,
                addedAt: (new Date(i.created * 1000)).toLocaleString("fr-FR", { dateStyle: "long", timeStyle: "medium" }),
                action: i
              });
            });
            setCarriersList(carriers);
          }
          
        function deleteCarrier(carrierId) {
            setIsDeletingCarrier(true)
            fetch(`${misterCoockyApi.misterCoockyApi}/api/user-pros/`+localStorage.getItem("userId")+`/remove-carriers`, {
              method: "PUT",
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
                "Content-Type": "application/json",
              },
              body: JSON.stringify([carrierId]),
            })
              .then((res) => res.json())
              .then(function (result) {
                console.log("result of the deletion : "+result)
                setCarriersLoaded(false)
                refreshList();
                setSelectedCarrierToDelete(null);
                setIsDeletingCarrier(false)
              });
          }

          
    const options = {
        selectableRows: false,
        responsive: 'simple',
        textLabels: {
            body: {
                noMatch: 'Aucun transporteur renseigné',
                toolTip: 'Trier'
                
            },
            pagination: {
                next: "Page suivante",
                previous: "Page précédente",
                rowsPerPage: "Transporteurs par page: ",
                displayRows: "sur",
            },
            toolbar: {
                search: "Rechercher un transporteur",
                downloadCsv: "Télécharger les transporteurs au format CSV",
                print: "Imprimer les transporteurs",
                viewColumns: "Afficher les colonnes",
                filterTable: "Trier le tableau",
              },
              filter: {
                all: "Tous",
                title: "Filtres",
                reset: "Réinitialiser",
              },
              viewColumns: {
                title: "Colonnes à afficher",
                titleAria: "Afficher/Cacher colonne",
              },
              selectedRows: {
                text: "Transporteur(s) sélectionné(s)",
                delete: "Supprimer",
                deleteAria: "Supprimer transporteur(s) sélectionné(s)",
              },
        }
    };
    const columns = [
        {
            name: "logo",
            label: "Logo",
            options: {
              customBodyRender: function (data) {
                if (data) {
                  return <img src={data} width={80} />;
                } else return "N/A";
              },
              filter: false,
              sort: false,
              download: false,
            }
        },
        {
            name: "carrierName",
            label: "Transporteur",
        },
        {
            name: "rates",
            label: "Grille tarifaire officielle",
            options: {
              customBodyRender: function (res) {               
                return  <div>
                          <Link
                            onClick={event => window.open(
                              res
                                )}
                            style={{
                              color: "#D94A46",
                              textAlign: "center",
                              marginTop: 10,
                              marginBottom: 40,
                              cursor: "pointer"
                            }}
                            >
                          Voir la grille
                          <LaunchIcon
                            style={{
                              fontSize: 18,
                              marginLeft: 5,
                              padding: 0,
                              marginBottom: -5,
                            }}
                          ></LaunchIcon>
                          </Link>              
                        </div>  
             },
             filter: false 
            }
        },
        {
            name: "addedAt",
            label: "Ajouté le",
            options: {
                customBodyRender: function (res) {
                    return <span>{res}</span>
                    
                }
            }
        },
        {
            name: "action",
            label: "Actions",
            options: {
                customBodyRender: function (res) {
                    return <div>
                        <IconButton aria-label="Supprimer" title="Supprimer" style={{padding: "3px"}} onClick={() => setSelectedCarrierToDelete(res)}>
                            <DeleteOutlinedIcon style={{color: colors.grey}}/>
                        </IconButton>
                    </div>
                },
                filter: false,
                sort: false,
                download: false,
                print: false
            }
        },

    ];

    return(
        <Container style={{width: "100%", paddingLeft:0, paddingRight:0}} >
          <Modal
            isOpen={selectedCarrierToDelete !== null}
            onRequestClose={() => setSelectedCarrierToDelete(null)}
            style={customStyles}
          >
          <h2 style={{fontWeight: "bold", margin: "0 auto", marginBottom: 40}}>Êtes-vous sûr ?</h2>
          <div style={{marginBottom: 40}}>Souhaitez-vous retirer {selectedCarrierToDelete?.carrierNickname} de la liste de vos transporteurs ?</div>
          <img src={selectedCarrierToDelete?.carrierLogo} style={{width:"30%", marginLeft: "auto", marginRight: "auto", marginBottom: 40}}/>
          <div  style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
            <Button disabled={isDeletingCarrier} style={{ width: 120, backgroundColor: colors.white, border: "2px solid #DEC8A6", borderRadius: 10, "&:hover": {color: "red"}}} onClick={() => setSelectedCarrierToDelete(null)}>
              Non
            </Button>
            <Button disabled={isDeletingCarrier} style={{ width: 120, backgroundColor: colors.brown, color: "#ffffff", border: "2px solid #DEC8A6", borderRadius: 10, "&:hover": {color: "red"} }} onClick={() => deleteCarrier(selectedCarrierToDelete?.carrier_id)}>
              Oui {isDeletingCarrier ? <CircularProgress style={{marginLeft: 10}} color={colors.brown} size={18} /> : null}
            </Button>
          </div>

        </Modal>
            <div  style={{width: "100%", display: 'flex', justifyContent: 'center', paddingLeft:0, paddingRight:0}} >
            {carriersLoaded ?
                <ThemeProvider theme={getMuiTheme()} >
                    <MUIDataTable
                        className="my-custom-table"
                        title={"Mes transporteurs"}
                        columns={columns}
                        options={options}
                        data={carriersList}
                    />
                </ThemeProvider>
                : (
                <CircularProgress
                color={colors.brown}
                size={108}
                style={{marginTop: 20}}
                />
            )}

            </div>
        </Container>
    )
}