import './App.css';
import  themeFont from './themes/font';
import { useEffect } from 'react';
import { ThemeProvider } from 'react-bootstrap';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { Redirect } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import {
  AddAnnonce,
  Addproduct,
  FilesControlPanel,
  Home,
  HomeAdmin,
  MyAnnoncesPage,
  MyProductPage,
  OrganizationInfo,
  Register,
  SignIn,
  Statistics,
  StatisticsMrAdopt,
  UserProfile,ForgotPass
} from './pages';
import React from "react";
import SecuredRoute from "./components/SecuredRoute";
import PublicRoute from "./components/PublicRoute";
import ResetPass from './pages/ResetPassword';
import Protected from './components/Protected';

import packageJson from '../package.json';


const queryClient = new QueryClient({});

function App() {
/*   useEffect(() => {
    console.log("userId: " + localStorage.getItem('userId'))
    console.log("accessToken: " + localStorage.getItem('accessToken'))
    console.log("App version: " + packageJson.version)
  },[]) */


  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={themeFont}>
          <Router>
            <Switch>
                <SecuredRoute path="/home"><Home/></SecuredRoute>
                <SecuredRoute path="/homeAdmin"><HomeAdmin/></SecuredRoute>
                <SecuredRoute path="/filesControlPanel"><FilesControlPanel/></SecuredRoute>
                <Route path="/organizationInfos" render={(props) => <OrganizationInfo {...props.location.state}/>} />
                <SecuredRoute path="/statistics"><Statistics/></SecuredRoute>
                <SecuredRoute path="/products"><MyProductPage/></SecuredRoute>
                <SecuredRoute path="/annonces"><MyAnnoncesPage/></SecuredRoute>
                <SecuredRoute path="/addproduct">
                  <Route path="/addproduct" render={(props) => <Addproduct {...props.location.state}/>} />
                </SecuredRoute>
                <SecuredRoute path="/addAnnonce">
                  <Route path="/addAnnonce" render={(props) => <AddAnnonce {...props.location.state}/>} />
                </SecuredRoute>
                <SecuredRoute path="/userProfile"><UserProfile/></SecuredRoute>
                <SecuredRoute path="/statisticsMrAdopt"> <StatisticsMrAdopt/></SecuredRoute>
                {/*<SecuredRoute path="/orders"><Orders/></SecuredRoute>*/}
                <PublicRoute path="/register"><Register/></PublicRoute>
                <PublicRoute path="/signIn"><SignIn/></PublicRoute>
                <PublicRoute path="/forgot-password"><ForgotPass/></PublicRoute>
                <Protected path="/reset-password"><ResetPass/></Protected>
                <SecuredRoute path="/"><Redirect to={{
                pathname: "/home"
                }}/></SecuredRoute>
            </Switch>
          </Router>
      </ThemeProvider>
    </QueryClientProvider>

/*       <ThemeProvider theme={themeFont}>
        <Router>
        <Switch>
          <SecuredRoute path="/home"><Home/></SecuredRoute>
          <SecuredRoute path="/homeAdmin"><HomeAdmin/></SecuredRoute>
          <SecuredRoute path="/filesControlPanel"><FilesControlPanel/></SecuredRoute>
          <Route path="/organizationInfos" render={(props) => <OrganizationInfo {...props.location.state}/>} />
          <SecuredRoute path="/statistics"><Statistics/></SecuredRoute>
          <SecuredRoute path="/products"><MyProductPage/></SecuredRoute>
          <SecuredRoute path="/annonces"><MyAnnoncesPage/></SecuredRoute>
          <Route path="/addproduct" render={(props) => <Addproduct {...props.location.state}/>} />
          <Route path="/addAnnonce" render={(props) => <AddAnnonce {...props.location.state}/>} />
          <SecuredRoute path="/userProfile"><UserProfile/></SecuredRoute>
          <SecuredRoute path="/statisticsMrAdopt"> <StatisticsMrAdopt/></SecuredRoute>
          <Route path="/register"><Register/></Route>
          <Route path="/signIn"><SignIn/></Route>
          <Route path="/forgot"><ForgotPass/></Route>
          <Protected path="/reset-password"><ResetPass/></Protected>
          <Route path="/"><SignIn/></Route>


        </Switch>
      </Router>
      </ThemeProvider> */


    
  );
}

export default App;
