import * as Yup from "yup";
import { fr } from "yup-locales";
import { setLocale } from "yup";

setLocale(fr);

export function ValidationSchemas() {

    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    const emailRegex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
    const lowercaseRegEx = /(?=.*[a-z])/;
    const uppercaseRegEx = /(?=.*[A-Z])/;
    const numericRegEx = /(?=.*[0-9])/;
    const passwordRegEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{4,}$/;

    let NomEntrepriseSchema = Yup.object().shape({
        nomentreprise: Yup.string()
        .required("Le nom d'entreprise est requis")
        .min(1, "Le nom d'entreprise est trop court - doit comporter au moins 1 caractère.")
        .max(256, "Le nom d'entreprise est trop long - doit être de 256 caractères maximum.")
    })

    let AdresseSchema = Yup.object().shape({
        adress: Yup.string().required("Adresse requise")
    })

    let TypeLenseigneSchema = Yup.object().shape({
        TypeLenseigne: Yup.array().nullable().required("Le type de l'enseigne est requis")
    })

    let OffresSchema = Yup.object().shape({
        offres: Yup.array().required("Le type d'offre est requis")
    })

    let NomSchema = Yup.object().shape({
        nom: Yup.string()
        .required("Le nom est requis")
        .min(1, "Le nom est trop court - doit comporter au moins 1 caractère.")
        .max(256, "Le nom est trop long - doit être de 256 caractères maximum.")
    })

    let PrenomSchema = Yup.object().shape({
        prenom: Yup.string()
        .required("Le prénom est requis")
        .min(1, "Le prénom est trop court - doit comporter au moins 1 caractère.")
        .max(256, "Le prénom est trop long - doit être de 256 caractères maximum.")
    })

    let EmailSchema = Yup.object().shape({
        email: Yup.string()
        .required("L'adresse e-mail est requise")
        .min(4, "L'adresse e-mail est trop courte - doit comporter au moins 4 caractères.")
        .matches(emailRegex, "L'adresse e-mail doit être valide !")
        .max(320, "L'adresse e-mail est trop longue - doit être de 320 caractères maximum.")
     })

    let PhoneSchema = Yup.object().shape({
      phone: Yup.string()
      .required("Le numéro de téléphone est requis")
      .length(10, "Le numéro de téléphone est invalide - doit comporter 10 chiffres.")
      .matches(phoneRegex, "Le numéro de téléphone doit être valide !")
     })

     let OldPasswordSchema = Yup.object().shape({
        oldPassword: Yup.string().required("L'ancien mot de passe est requis")
     })


     let PasswordSchema = Yup.object().shape({
        password: Yup.string()
         .required("Entrez le nouveau mot de passe.")
         .min(4, "Le mot de passe est trop court - doit comporter au moins 4 caractères.")
         .matches(lowercaseRegEx, "Doit contenir des lettres minuscules !")
         .matches(uppercaseRegEx, "Doit contenir des lettres majuscules !")
         .matches(numericRegEx, "Doit contenir des chiffres !")
         .matches(passwordRegEx, "Doit contenir des chiffres !")
     })

     let CfPasswordSchema = Yup.object().shape({
        cfpassword: Yup.string()
         .required("Confirmez le mot de passe")
         .oneOf([Yup.ref("password")], "Les mots de passe ne correspondent pas")
     })



    
    return {
        NomEntrepriseSchema,
        AdresseSchema,
        TypeLenseigneSchema,
        OffresSchema,
        NomSchema,
        PrenomSchema,
        EmailSchema,
        PhoneSchema,
        OldPasswordSchema,
        PasswordSchema,
        CfPasswordSchema
    }
        

}

