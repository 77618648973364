import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Slide from "@material-ui/core/Slide";
import { colors } from "../themes/colors";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import misterCoockyApi from "../constante/apiUrl";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { PersistentDrawerLeft } from ".";

const drawerWidth = 240;

/**
 * Permet de gérer le menu déroulant
 * @param props
 * @returns {*}
 * @constructor
 */
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
HideOnScroll.propTypes = {
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100vw',
        justifyContent: 'center',
    },
  
    title: {
        width: 'fit-content',
        fontWeight: 100,
        [theme.breakpoints.between('xs', 'md')]: {
            fontSize: 14,
          },
    },

  title: {
    width: "fit-content",
    fontWeight: 300,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: 14,
    },
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: colors.brown,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuItemsStyle: {
    fontWeight: "bold",
    "&:hover": {
      color: "red",
    },
  },
}));

/***
 * Header principal de l'application
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userName, setUserName] = useState(null);
  const [admin, setAdmin] = useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


    /**
     * Récupération du userpro
     */
    useEffect(() => {
        //si utilisateur non connecté
        if(localStorage.getItem('userId') === null && localStorage.getItem('organizationId') === null && localStorage.getItem('supplierId') === null){
            window.location.href = '/signIn';
        }else{
            fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
                    'Content-Type': 'application/json', 
                },
                body: JSON.stringify({
                    query: `
                    query{​​​​​​​
                        userPro(id:"${localStorage.getItem("userId")}"){​​​​​​​
                            id
                            user{
                                name
                                role{
                                    name
                                }
                            }
                            organization{
                                name
                            }
                        }​​​​​​​
                    }`,
                }),
            })
                .then((res) => res.json())
                .then((json) => {
                    console.log("token : " + localStorage.getItem('accessToken'))
                    console.log(json)
                    if(json.data.userPro){
                        setAdmin(json.data.userPro.user.role?.name);
                        setUserName(json.data.userPro.organization.name)
                    }
                    else if(json.data.userPro === null) {
                        handleDisconnect()
                    }
                })
        }
    }, []);

    /**
     * lors du clic du menu déroulant
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * Lors du clic sur déconnexion
     */
    const handleDisconnect = () => {
/*         localStorage.setItem('userId', null);
        localStorage.setItem('organizationId', null);
        localStorage.setItem('supplierId', null);
        localStorage.setItem('accessToken', null); */
        
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('organizationId');
        localStorage.removeItem('supplierId');
        window.location.reload(true)
        //window.location.href = '/signIn';
    };
    const handleProfile = () => {
        window.location.href = '/userProfile';
    };

    /**
     * lors de la fermeture du menu déroulant
     */

    return (
        <React.Fragment>
            <div className={classes.root}>
                <AppBar position="static" style={{backgroundColor: colors.brown}}>
                    <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexDirection:'row', alignItems: 'center'}}>
                        <PersistentDrawerLeft/>
                        <Typography variant="h6" className={classes.title}  onClick={() => {admin === "ROLE_ADMIN"? window.location.href = '/homeAdmin' : window.location.href = '/home'}} style={{cursor:'pointer'}}>
                            Mister Coocky <span style={{fontWeight: 700}}>Pro</span> 
                        </Typography>
                        </div>

                        {userName !== null ? (
                            <div>
                            <Button style={{color: colors.white, fontSize:'16px', marginRight: 10}}   justify='flex-end'        aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true" endIcon={<Icon><ArrowDropDownIcon/></Icon>} ref={anchorRef} onClick={handleToggle}>
                                <span style={{marginTop:'4px'}}>{userName}{admin === "ROLE_ADMIN"? " [Admin]":null}</span>
                            </Button>

                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={handleProfile}
                              className={classes.menuItemsStyle}
                            >
                              Profil
                            </MenuItem>
                            <MenuItem
                              onClick={handleDisconnect}
                              className={classes.menuItemsStyle}
                            >
                              Se déconnecter
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            ) : null}
          </Toolbar>
        </AppBar>
      </div>
    </React.Fragment>
    ) 
  }
