import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import DataProvider from "./Redux/store";

ReactDOM.render(

        <DataProvider>
          <App />
        </DataProvider>,

  document.getElementById('root')
);


// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
