import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../../themes/colors";

export const useStyles = makeStyles((theme) => ({
    inputBox:{
        width:'100%',
        backgroundColor:colors.whiteTr,
        padding:'15px 3px',
        [theme.breakpoints.between('xs', 'md')]: {
            display: 'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: "center",
          },

    },
    Field:{
        marginBottom:15,
        [theme.breakpoints.between('xs', 'md')]: {
                width: 230
          },
    },
    Container:{
        backgroundColor:'#fff',
        width:"100%",
        display:'flex',
        justifyContent:'space-between',
        padding:10,
        [theme.breakpoints.between('xs', 'md')]: {
            width: 230,
            display: 'flex',
            flexDirection:'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: "center",
          },
    },
    containerTO: {
        marginLeft: 40,
        width:"100%",
        [theme.breakpoints.between('xs', 'md')]: {
            width: 230,
            marginLeft: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            alignItems: 'left',
            alignContent: "left",
          },
    },
    containerTE: {
        width: 500,
        [theme.breakpoints.between('xs', 'md')]: {
            width: 230,
            marginLeft: 0,
            display: 'flex',
            flexDirection:'column',
            justifyContent: 'left',
            alignItems: 'left',
            alignContent: "left",
            marginBottom: 20
          },
    },
    item:{
        backgroundColor:'#fff',
        margin:'5px 0',
        padding:4
    },
    textLabel:{
        fontWeight:'400',
        paddingLeft:10
    }
  }));