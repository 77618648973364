import React from "react";
import { useEffect, useState } from "react";
import { Field } from "formik";
import { Box, Button, Grid, TextField, Text } from "@material-ui/core";
import { useStyles } from "./Styles";
import { colors } from "../../../../themes/colors";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import ShowPasswordButton from "../../../../components/ShowPasswordButton";

import "./FormStyles.css";

function From1({
  formik,
  alertMail,
  onSubmit,
  fetchEmail,
  ChampVide,
  ActivateAlert,
}) {
  const classes = useStyles();
  const [formError, setFormError] = useState(true);
  const [isSigninUp, setIsSigninUp] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailAlertMsg, setEmailAlertMsg] = useState(alertMail);
  const { isSubmitting, isValid } = formik;
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    checkFormError();
  }, [
    formik.errors.nom,
    formik.errors.prenom,
    formik.errors.email,
    formik.errors.phone,
    formik.errors.password,
    formik.errors.cfpassword,
  ]);

  const AlertItem = () => {
    return ChampVide ? (
      <Alert
        severity="error"
        fullwidth
        style={{ margin: "5px 0px 15px 0px", width: "100%", color: "#f00" }}
      >
        {ChampVide}
      </Alert>
    ) : (
      <></>
    );
  };

  const AlertMailItem = () => {
    return emailAlertMsg && emailError ? (
      <Alert severity="error" fullwidth>
        {emailAlertMsg}
      </Alert>
    ) : (
      <></>
    );
  };

  const checkFormError = () => {
    if (emailAlertMsg === "Email déjà utilisée !") {
      setEmailError(true);
    }
    setFormError(true);

    if (
      formik?.values?.nom?.length &&
      formik?.errors.nom == null &&
      formik?.values.prenom.length &&
      formik?.errors.prenom == null &&
      formik?.values.email.length &&
      formik?.errors.email == null &&
      formik?.values.phone.length &&
      formik?.errors.phone == null &&
      formik?.values.password.length &&
      formik?.errors.password == null &&
      formik?.values.cfpassword.length &&
      formik?.errors.cfpassword == null
    ) {
      setFormError(false);
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      checkFormError();
      NextPage();
    }
  };

  const NextPage = () => {
    checkFormError();
    if (formError === false) {
      setIsSigninUp(false);
      fetchEmail(formik?.values.email);
      setIsSigninUp(true);
    } else if (formError === true && alertMail === "") {
      ActivateAlert();
      setFormError(true);
      setIsSigninUp(false);
      formik.setFieldTouched("nom", true);
      formik.setFieldTouched("prenom", true);
      formik.setFieldTouched("email", true);
      formik.setFieldTouched("phone", true);
      formik.setFieldTouched("password", true);
      formik.setFieldTouched("cfpassword", true);
    } else if (formError === false && alertMail === "") {
      onSubmit();
      setIsSigninUp(true);
    }
  };

  return (
    <Box className={classes.ContainerForm1}>
      <AlertItem />

      <Box className={classes.hdrMsg}>
        <Box style={{ color: "#D94A46", marginRight: 3, fontSize: 20 }}>* </Box>
        Champs obligatoires
      </Box>
      <Grid container justify="space-between">
        <div className={classes.formTextDiv}>
          <Field
            variant="outlined"
            required
            name="nom"
            label="Nom"
            InputLabelProps={{
              margin: "dense",
            }}
            InputProps={{ style: { height: 40 } }}
            type="text"
            id="nom"
            validate={checkFormError}
            onKeyDown={handleEnterPress}
            onInput={() => {
              formik.setFieldTouched("nom", true);
            }}
            className={classes.formText}
            as={TextField}
            error={formik.errors.nom && formik.touched.nom ? true : false}
            disabled={isSigninUp ? true : false}
            helperText={
              formik.errors.nom && formik.touched.nom ? formik.errors.nom : ""
            }
          />
        </div>
        <div className={classes.formTextDiv}>
          <Field
            variant="outlined"
            required
            name="prenom"
            label="Prénom"
            InputLabelProps={{
              margin: "dense",
            }}
            InputProps={{ style: { height: 40 } }}
            type="text"
            id="prenom"
            className={classes.formText}
            as={TextField}
            validate={checkFormError}
            onKeyDown={handleEnterPress}
            onInput={() => {
              formik.setFieldTouched("prenom", true);
            }}
            disabled={isSigninUp ? true : false}
            error={formik.errors.prenom && formik.touched.prenom ? true : false}
            helperText={
              formik.errors.prenom && formik.touched.prenom
                ? formik.errors.prenom
                : ""
            }
          />
        </div>
      </Grid>

      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        InputLabelProps={{
          margin: "dense",
        }}
        InputProps={{ style: { height: 40 } }}
        type="email"
        name="email"
        as={TextField}
        validate={checkFormError}
        onKeyDown={handleEnterPress}
        onInput={() => {
          setEmailAlertMsg("");
          setEmailError(false);
          formik.setFieldTouched("email", true);
        }}
        disabled={isSigninUp ? true : false}
        error={
          (formik.errors.email && formik.touched.email) || emailAlertMsg != ""
            ? true
            : false
        }
        helperText={
          formik.errors.email && formik.touched.email ? formik.errors.email : ""
        }
      />

      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="phone"
        label="Téléphone"
        InputLabelProps={{
          margin: "dense",
        }}
        InputProps={{ style: { height: 40 } }}
        type="tel"
        name="phone"
        as={TextField}
        onInput={() => {
          formik.setFieldTouched("phone", true);
        }}
        validate={checkFormError}
        onKeyDown={handleEnterPress}
        disabled={isSigninUp ? true : false}
        error={formik.errors.phone && formik.touched.phone ? true : false}
        helperText={
          formik.errors.phone && formik.touched.phone ? formik.errors.phone : ""
        }
      />

      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type={showPassword1 ? "text" : "password"}
        InputProps={{
          style: { height: 40 },
          endAdornment: (
            <ShowPasswordButton
              disabled={isSigninUp}
              handleClickShowPassword={handleClickShowPassword1}
              handleMouseDownPassword={handleMouseDownPassword}
              showPassword={showPassword1}
            />
          ),
        }}
        name="password"
        id="password"
        label="Mot de passe"
        InputLabelProps={{
          margin: "dense",
        }}
        as={TextField}
        onInput={() => {
          formik.setFieldTouched("password", true);
        }}
        validate={checkFormError}
        onKeyDown={handleEnterPress}
        disabled={isSigninUp ? true : false}
        error={formik.errors.password && formik.touched.password ? true : false}
        helperText={
          formik.errors.password && formik.touched.password
            ? formik.errors.password
            : ""
        }
      />

      <Field
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type={showPassword2 ? "text" : "password"}
        InputProps={{
          style: { height: 40 },
          endAdornment: (
            <ShowPasswordButton
              disabled={isSigninUp}
              handleClickShowPassword={handleClickShowPassword2}
              handleMouseDownPassword={handleMouseDownPassword}
              showPassword={showPassword2}
            />
          ),
        }}
        name="cfpassword"
        id="cfpassword"
        label="Valider le mot de passe"
        onKeyDown={handleEnterPress}
        InputLabelProps={{
          margin: "dense",
        }}
        as={TextField}
        validate={checkFormError}
        onInput={() => {
          formik.setFieldTouched("cfpassword", true);
        }}
        disabled={isSigninUp ? true : false}
        error={
          formik.errors.cfpassword && formik.touched.cfpassword ? true : false
        }
        helperText={
          formik.errors.cfpassword && formik.touched.cfpassword
            ? formik.errors.cfpassword
            : ""
        }
      />

      <AlertMailItem />

      <Box className={classes.containerButtons}>
        <Link to="/signIn" className="custom-links">
          Déjà un compte ? Se connecter
        </Link>
        <Button
          onClick={() => {
            NextPage();
            checkFormError();
          }}
          variant="contained"
          className="default-btn"
          disabled={
            formik.isSubmitting || isSigninUp || formError || emailAlertMsg !== ""
              ? true
              : false
          }
        >
          Suivant
          {isSigninUp && (
            <CircularProgress
              color={colors.white}
              size={18}
              style={{ marginLeft: 10 }}
            />
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default From1;
