import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import connexion from "../images/Connexion.png";
import logo from "../images/logo.svg";
import { colors } from "../themes/colors";
import Box from "@material-ui/core/Box";
import { Alert } from "@material-ui/lab";
import Link from "@material-ui/core/Link";
import misterCoockyApi from "../constante/apiUrl";
import ShowPasswordButton from "../components/ShowPasswordButton";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: colors.white,
  },
  image: {
    backgroundImage: `url(${connexion})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: colors.white,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    marginTop: "50px",
  },
  btn: {
    padding: theme.spacing(1),
    color: colors.white,
    backgroundColor: "#DEC8A6",
    fontWeight: "700",
    width: "fit-content",
  },
  formText: {
    width: "35%",
  },
  centerElements: {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "flex-end",
    "align-items": "flex-end",
    paddingRight: 40,
    [theme.breakpoints.between("xs", "md")]: {},
  },
}));

/**
 * Page de Connexion
 * @returns {*}
 * @constructor
 */
function Connection() {
  const [email, setEmail] = useState("");
  const [motDePasse, setMotDePasse] = useState("");
  const [signError, setSignError] = useState("");
  const [isSigninIn, setIsSigninIn] = useState(false);
  const classes = useStyles();
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Envoi du formulaire de connexion
   * @param e
   */
  function handleSubmit(e) {
    setIsSigninIn(true);
    e.preventDefault();
    fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
                    query{​​​​​​​
                        logAsUser(email:"${email}", password: "${motDePasse}"){
                            userPro{
                                id
                                organization{
                                    id
                                    supplier{
                                        id  
                                    }
                                }
                                user{
                                    role{
                                        name
                                    }
                                }
                            }
                            access_token
                        }
                    }`,
            }),
        })
            .then((res) => res.json())
            .then(function (result) {
                console.log( "result.data : " + JSON.stringify(result.data))
                if (result.data.logAsUser == null) {
                    setIsSigninIn(false)
                    setSignError("Email ou mot de passe incorrects");
                    
                } else {
                    localStorage.setItem('userId', result.data.logAsUser.userPro.id);
                    localStorage.setItem('organizationId', result.data.logAsUser.userPro.organization.id);
                    localStorage.setItem('supplierId', result.data.logAsUser.userPro.organization.supplier.id);
                    console.log("access token: " + result.data.logAsUser.access_token)
                    console.log( "result.data : " + JSON.stringify(result.data))
                    localStorage.setItem('accessToken', result.data.logAsUser.access_token);
                    if (result.data.logAsUser.userPro.user.role?.name === "ROLE_USER") {
                        window.location.href = '/home';
                    } else {
                        window.location.href = '/homeAdmin';
                    }
                }
            });
    }

  /**
   * Récupération du contenu de page
   * @returns {*}
   */
  function getFormContent() {
    return (
      <>
        <TextField
          required
          variant="outlined"
          type="email"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          InputLabelProps={{ margin: "dense" }}
          InputProps={{ style: { height: 40 } }}
          name="remail"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          disabled={isSigninIn ? true : false}
        />
        <TextField
          required
          variant="outlined"
          margin="normal"
          fullWidth
          id="phone"
          label="Mot de passe"
          InputLabelProps={{ margin: "dense" }}
          type={showPassword1 ? "text" : "password"}
          InputProps={{
            style: { height: 40 },
            endAdornment: (
              <ShowPasswordButton
                disabled={isSigninIn}
                handleClickShowPassword={handleClickShowPassword1}
                handleMouseDownPassword={handleMouseDownPassword}
                showPassword={showPassword1}
              />
            ),
          }}
          name="phone"
          onChange={(e) => setMotDePasse(e.target.value)}
          value={motDePasse}
          disabled={isSigninIn ? true : false}
        />
        <Box m={0.5}>
          <Link className="custom-links" href={"/forgot-password"}>
            Mot de passe oublié ?
          </Link>
        </Box>

        {signError ? <Alert severity="error">{signError}</Alert> : <></>}

        <Box mt={10}>
          <Grid container spacing={10} className={classes.centerElements}>
            <Button
              type="submit"
              variant="contained"
              disabled={
                isSigninIn || motDePasse.length === 0 || email.length === 0
                  ? true
                  : false
              }
              style={{ backgroundColor: colors.brown }}
              className="default-btn"
            >
              Se connecter
              {isSigninIn && (
                <CircularProgress
                  color={colors.white}
                  size={18}
                  style={{ marginLeft: 10 }}
                />
              )}
            </Button>
            <Box style={{ marginTop: 5 }}>
              <Link
                href={"/register"}
                style={{ textDecoration: "none" }}
                className="custom-links"
              >
                Pas de compte? S'inscrire
              </Link>
            </Box>
          </Grid>
        </Box>
      </>
    );
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={6} md={6} className={classes.image} />
      <Grid item xs={12} sm={6} md={6}>
        <div className={classes.paper}>
          <Grid>
            <img alt="Logo Mister Coocky" width={350} height={250} src={logo} />
          </Grid>
          <form className={classes.form} onSubmit={handleSubmit}>
            {getFormContent()}
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default Connection;
