import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import LinkUI from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { colors } from "../themes/colors";
import CardMedia from "@material-ui/core/CardMedia";
import misterCoocky from "../images/misterCoocky.png";
import logo from "../images/logo.svg";
import facebook from "../images/face.svg";
import instagram from "../images/instagram.svg";
import tiktok from "../images/tiktok.svg";
import Card from "@material-ui/core/Card";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useHistory, Link } from "react-router-dom";
import packageJson from '../../package.json';

let drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: colors.brown,
  },
  appBarShift: {
    width: `calc(100% - ${0}px)`,

    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    "&:hover": {
      color: "#D94A46",
    },
  },
  hide: {
    "&:hover": {
      color: "#D94A46",
    },
  },
  drawer: {
    display: "flex",
    justifyContent: "space-around",
    height: "100vh",
  },
  flexSeparator: {
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: colors.white,
  },
  textBlack: {
    color: "#000",
    fontWeight: 700,
    "&:hover": {
      color: "#D94A46",
    },
  },
  redHover: {
    "&:hover": {
      color: "#D94A46",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: "#000",
    "&:hover": {
      color: "#D94A46",
    },
  },
  cardAction: {
    display: "block",
    "&:hover": {
      color: "#D94A46",
    },
  },
  img: {
    width: "20%",
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
  },
  imageContent: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "0 auto",
    marginBottom: 20,
  },
  imageSize: {
    width: 200,
    height: 120,
    objectFit: "contain",
    margin: "0 auto",
  },
  itemSMR: {
    "&:hover": {
      color: "#D94A46",
      "& $textBlack": {
        color: "#D94A46",
      },
    },
  },
}));

export default function PersistentDrawerLeft(props) {
  const today = new Date();
  const classes = useStyles(props);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openSubOne, setOpenSubOne] = React.useState(false);
  const [openSubTwo, setOpenSubTwo] = React.useState(false);
  let history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClickOne = () => {
    setOpenSubOne(!openSubOne);
  };
  const handleClickTwo = () => {
    setOpenSubTwo(!openSubTwo);
  };
  if (open) {
    drawerWidth = 240;
  } else {
    drawerWidth = 0;
  }
  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        className={clsx(classes.menuButton, open && classes.hide)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.flexSeparator}>
          <div>
            <div className={classes.drawerHeader}>
              <IconButton className={classes.hide} onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>

            <CardMedia
              component="img"
              image={logo}
              className={classes.imageSize}
              title="Logo"
              alt="Logo"
            />
            <div
              style={{ width: "100%", textAlign: "center", marginBottom: 10 }}
            >
              <span
                className="drawer-logo-title"
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                }}
              >
                dénicheur sain, malin et responsable
              </span>
            </div>

            <Divider />
            <List>
              <ListItem
                button
                className={classes.itemBold}
                onClick={() => {
                  history.push("/home");
                }}
              >
                <ListItemText className={classes.textBlack} primary="Accueil" />
              </ListItem>
              <ListItem
                style={{ color: openSubOne && "#D94A46" }}
                className={classes.itemSMR}
                button
                onClick={handleClickOne}
              >
                <ListItemText
                  className={classes.textBlack}
                  style={{ color: openSubOne && "#D94A46" }}
                  primary="Mr.Market"
                />
                {openSubOne ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openSubOne} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/products" style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <ListItemText
                        className={classes.textBlack}
                        primary="Liste des produits"
                      />
                    </ListItem>
                  </Link>
                  <LinkUI href="/addproduct" style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <ListItemText
                        className={classes.textBlack}
                        primary="Ajouter des produits"
                      />
                    </ListItem>
                  </LinkUI>
                  <LinkUI href="/statistics" style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <ListItemText
                        className={classes.textBlack}
                        primary="Statistiques"
                      />
                    </ListItem>
                  </LinkUI>
                </List>
              </Collapse>

              <ListItem
                style={{ color: openSubTwo && "#D94A46" }}
                className={classes.itemSMR}
                button
                onClick={handleClickTwo}
              >
                <ListItemText
                  style={{ color: openSubTwo && "#D94A46" }}
                  className={classes.textBlack}
                  primary="Mr. Adopt"
                />
                {openSubTwo ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openSubTwo} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <LinkUI href="/annonces" style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <ListItemText
                        className={classes.textBlack}
                        primary="Liste des annonces"
                      />
                    </ListItem>
                  </LinkUI>
                  <LinkUI href="/addannonce" style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <ListItemText
                        className={classes.textBlack}
                        primary="Ajouter des annonces"
                      />
                    </ListItem>
                  </LinkUI>
                  <LinkUI
                    href="/statisticsMrAdopt"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem button className={classes.nested}>
                      <ListItemText
                        className={classes.textBlack}
                        primary="Statistiques des annonces"
                      />
                    </ListItem>
                  </LinkUI>
                </List>
              </Collapse>
              <LinkUI
                href="https://mister-coocky.fr/contact.html"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <ListItem button className={classes.itemSMR}>
                  <ListItemText
                    className={classes.textBlack}
                    primary="Contact"
                  />
                </ListItem>
              </LinkUI>
            </List>
          </div>

          <div className={classes.imageContent}>
            <Card className={classes.img}>
              <ButtonBase
                className={classes.cardAction}
                onClick={(event) =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=100057654481188",
                    "_blank"
                  )
                }
              >
                <CardMedia
                  className="external-app"
                  component="img"
                  image={facebook}
                  title="Facebook"
                  alt="Facebook"
                />
              </ButtonBase>
            </Card>

            <Card className={classes.img}>
              <ButtonBase
                className={classes.cardAction}
                onClick={(event) =>
                  window.open(
                    "https://www.instagram.com/mister_coocky/",
                    "_blank"
                  )
                }
              >
                <CardMedia
                  className="external-app"
                  component="img"
                  image={instagram}
                  title="Instagram"
                  alt="Instagram"
                />
              </ButtonBase>
            </Card>

            <Card className={classes.img}>
              <ButtonBase
                className={classes.cardAction}
                onClick={(event) =>
                  window.open(
                    "https://www.tiktok.com/@mistercoocky?lang=fr",
                    "_blank"
                  )
                }
              >
                <CardMedia
                  className="external-app"
                  component="img"
                  image={tiktok}
                  title="TikTok"
                  alt="TikTok"
                />
              </ButtonBase>
            </Card>
          </div>
        </div>
        <div
          style={{
            color: "#777777",
            textAlign: "center",
            marginBottom: 20,
          }}
        >
          <span
            style={{
              fontSize: 14,
            }}
          >
            Mister Coocky Pro {packageJson.version}
          </span>
          <br />
          <span
            style={{
              fontSize: 12,
            }}
          >
            &#169; {today.getFullYear()} Copyright DataGrowb' limited{" "}
          </span>
        </div>
      </Drawer>
    </div>
  );
}
