import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { useStyles } from "../../hooks/Styles";
import Buttons from "./Components/Buttons";
import Inputs from "./Components/Inputs";
import OfferCheckbox from "./Components/OfferCheckbox";

function From2({
  onReturn,
  alertOrga,
  checkAvailableOrganization,
  TypeLenseigne,
  typeInscrire,
  formik,
  TypeOffre,
  formError
}) {
  const classes = useStyles();
  return (
    <Box className={classes.ContainerForm2}>
      <Inputs
        formik={formik} 
        checkAvailableOrganization={checkAvailableOrganization}
        alertOrga={alertOrga}       
      />
      <OfferCheckbox
        formik={formik}
        TypeLenseigne={TypeLenseigne}
        TypeOffre={TypeOffre}
        typeInscrire={typeInscrire}
      />
      <Buttons onReturn={onReturn} formik={formik}
              checkAvailableOrganization={checkAvailableOrganization}
              alertOrga={alertOrga}    
      />

    </Box>
  );
}

export default From2;


