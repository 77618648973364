import React from 'react'
import { Redirect } from "react-router-dom";

function PublicRoute({children}) {
    if (!localStorage.getItem('accessToken')){
        return(children)
    }
    else return <Redirect to={{
        pathname: "/"
    }}/>
}

export default PublicRoute;
