import { makeStyles } from "@material-ui/core";
import { colors } from "./colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  btn: {
    width: 250,
    fontWeight: "600",
    textTransform: "none",
    height: "fit-content",
    borderRadius: "10px",
    marginBottom: 20,
    "&:hover": {
      color: "red",
    },
    "@media (max-width: 1036px)": {
      fontSize: 12,
    },
    [theme.breakpoints.between("xs", "md")]: {
      width: "90%",
      marginBottom: 20,
    },
  },
  tabContainer: {
    display: "flex",
    width: "90%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "auto",
    [theme.breakpoints.between("xs", "md")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  formPassword: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
  },
  btnSubmit: {
    padding: theme.spacing(1),
    color: "#000",
    width: 140,
    textTransform: "capitalize",
    fontWeight: 700,
  },
  btnAsk: {
    padding: theme.spacing(1),
    color: "#000",
    width: 180,
    marginTop: 20,
    textTransform: "capitalize",
    fontWeight: 700,
  },
  btnSubmitPass: {
    padding: theme.spacing(1),
    color: "#000",
    width: 140,
    textTransform: "capitalize",
    fontWeight: 700,
    marginTop: 40,
  },

  btnImage: {
    color: "#000",
    width: 150,
    height: 35,
    fontSize: 12,
    textTransform: "capitalize",
  },
  formControl: {
    margin: theme.spacing.unit,
    width: 400,
    [theme.breakpoints.between("xs", "md")]: {
      width: 250,
    },
  },
  formControlO: {
    margin: theme.spacing.unit,
    marginLeft: 40,
    [theme.breakpoints.between("xs", "md")]: {
      marginLeft: 10,
    },
  },
  formControlNameContainer: {
    marginTop: 20,
    marginBottom: theme.spacing.unit,
    marginLeft: 105,
    display: "flex",
    width: 400,
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "md")]: {
      marginLeft: 8,
      width: 250,
      height: 130,
      flexDirection: "column",
    },
  },
  formControlName: {
    width: 190,
    [theme.breakpoints.between("xs", "md")]: {
      marginLeft: 8,
      width: 250,
    },
  },
  formControlUser: {
    width: 400,
    margin: theme.spacing.unit,
    marginLeft: 2,
    [theme.breakpoints.between("xs", "md")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginLeft: 8,
      width: 250,
    },
  },
  formControlPass: {
    width: 300,
    margin: theme.spacing.unit,
    [theme.breakpoints.between("xs", "md")]: {
      width: 230,
    },
  },
  grid: {
    marginTop: "10px",
    [theme.breakpoints.between("xs", "md")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  gridControl: {
    position: "absolute",
    minWidth: "150%",
    left: "20%",
  },
  boxBorder: {
    width: "100%",
    marginTop: -80,
    height: "fit-content" + 30,
    paddingBottom: 20,
    zIndex: 1,
    borderRadius: 5,
    boxShadow: "-1px 0px 14px 7px rgba(0,0,0,0.15)",
  },
  gridCheck: {
    marginLeft: 40,
    marginTop: 40,
    [theme.breakpoints.between("xs", "md")]: {
      marginTop: 0,
      marginLeft: 0,
    },
  },
  formControlTO: {
    marginLeft: 10,
    [theme.breakpoints.between("xs", "md")]: {
      marginTop: 20,
      marginLeft: 20,
    },
  },
  formLabelTO: {
    marginBottom: 10,
    [theme.breakpoints.between("xs", "md")]: {
      width: "120%",
      textAlign: "left",
    },
  },
  formControlLabelTE: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "150%",
      textAlign: "left",
    },
  },
  errorBox: {
    backgroundColor: "#fff",
    position: "absolute",
    left: "37%",
    top: 10,
    width: "30vw",
    height: "15vh",
    borderRadius: 6,
    zIndex: 10,
    border: `1px solid ${colors.red}`,
    color: colors.red,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  boldFields: {
    fontWeight: "bold",
  },
  spinner: {
    marginTop: 20,
  },
  form: {
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.between("xs", "md")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  orgaSectionLabel: {
    marginLeft: 47,
    [theme.breakpoints.between("xs", "md")]: {
      marginLeft: 20,
    },
  },
  userSectionLabel: {
    marginLeft: 47,
    [theme.breakpoints.between("xs", "md")]: {
      marginLeft: -5,
    },
  },
  logoUppperContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: 75,
    [theme.breakpoints.between("xs", "md")]: {
      marginLeft: 0,
    },
  },
  logoUpperContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logoContainerLabel: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "100%",
      textAlign: "left",
      marginLeft: 50,
    },
  },

  userLabelContainer: {
    [theme.breakpoints.between("xs", "md")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  userRequiredLabel: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    textAlign: "right",
    color: "#bdbdbd",
    alignSelf: "flex-end",
    marginRight: 100,
    [theme.breakpoints.between("xs", "md")]: {
      display: "none",
    },
  },
  editProfRequiredLabel: {
    display: "none",
    [theme.breakpoints.between("xs", "md")]: {
      display: "flex",
      width: "200%",
      justifyContent: "flex-end",
      marginTop: 10,
      textAlign: "right",
      marginRight: 20,
      color: "#bdbdbd",
    },
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",

    [theme.breakpoints.between("xs", "md")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  SMRprogressbar: {
    position: "absolute",
    width: 750,
    height: 0,
    top: "12px",
    zIndex: 0,
    left: "70px",
    border: "4px solid #DEC8A6",
    backgroundColor: "DEC8A6",
    [theme.breakpoints.between("xs", "md")]: {
      left: 71,
      top: 29,
      width: 0,
      height: 280,
    },
  },
  SMRpendingfirstpt: {
    position: "absolute",
    width: 29,
    height: 29,
    left: "41px",
    borderRadius: 180 / 2,
    [theme.breakpoints.between("xs", "md")]: {
      left: 60,
    },
  },
  SMRpendingsecondpt: {
    position: "absolute",
    width: 29,
    height: 29,
    left: 430,
    zIndex: 1,
    borderRadius: 180 / 2,
    [theme.breakpoints.between("xs", "md")]: {
      left: 60,
      top: 150,
    },
  },
  SMRpendingthirdpt: {
    position: "absolute",
    width: 29,
    height: 29,
    left: 820,
    zIndex: 1,
    borderRadius: 180 / 2,
    [theme.breakpoints.between("xs", "md")]: {
      left: 60,
      top: 300,
    },
  },
  SMRpendingcontainer: {
    height: "fit-content",
    paddingBottom: 80,
    width: "fit-content",
    [theme.breakpoints.between("xs", "md")]: {
      paddingBottom: 400,
    },
  },
  SMRpendingLabel1: {
    position: "absolute",
    top: "-150%",
    left: -35,
    fontWeight: 600,
    width: "400%",
    [theme.breakpoints.between("xs", "md")]: {
      top: 0,
      left: -80,
    },
  },
  SMRpendingLabel2: {
    position: "absolute",
    top: "-80%",
    width: "500%",
    left: -35,
    [theme.breakpoints.between("xs", "md")]: {
      top: 20,
      left: -100,
    },
  },
  SMRpendingLabel3: {
    position: "absolute",
    top: "150%",
    left: -75,
    fontWeight: 300,
    width: "600%",
    textAlign: "center",
    [theme.breakpoints.between("xs", "md")]: {
      width: "460%",
      top: 0,
      left: 32,
    },
  },
  SMRpendingLabel4: {
    position: "absolute",
    top: "-80%",
    width: "500%",
    left: -35,
    [theme.breakpoints.between("xs", "md")]: {
      top: 5,
      left: -105,
    },
  },
  SMRpendingLabel5: {
    position: "absolute",
    top: "150%",
    left: -75,
    fontWeight: 300,
    width: "600%",
    textAlign: "center",
    [theme.breakpoints.between("xs", "md")]: {
      width: "460%",
      top: 0,
      left: 32,
    },
  },
  SMRpendingLabel6: {
    position: "absolute",
    top: "-80%",
    width: "500%",
    left: -35,
    [theme.breakpoints.between("xs", "md")]: {
      top: 5,
      left: -105,
    },
  },
  SMRpendingLabel7: {
    position: "absolute",
    top: "150%",
    left: -75,
    fontWeight: 300,
    width: "600%",
    textAlign: "center",
    [theme.breakpoints.between("xs", "md")]: {
      width: "460%",
      top: 0,
      left: 32,
    },
  },
  CarrierLayout: {
    width: "70%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 40,
    marginBottom: 40,
    marginLeft: 40,
    [theme.breakpoints.between("xs", "md")]: {
      width: "100%",
      flexDirection: "column",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  CarrierLayoutLogo: {
    width: "60%",
    height: "60%",
    marginRight: 20,
    [theme.breakpoints.between("xs", "md")]: {
      width: 200,
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  OtherCarrierLayout: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 40,
    marginBottom: 40,
    [theme.breakpoints.between("xs", "md")]: {
      width: "100%",
      flexDirection: "column",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  OCLspan1: {
    fontSize: 14,
    marginTop: 20,
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: 13,
      marginBottom: 20,
    },
  },
  OCLspan2: {
    width: 400,
    fontSize: 14,
    marginTop: 20,
    textAlign: "left",
    [theme.breakpoints.between("xs", "md")]: {
      fontSize: 13,
      width: "100%",
    },
  },
  OCLTextField1: {
    width: 400,
    marginTop: 60,
    marginBottom: 20,
    [theme.breakpoints.between("xs", "md")]: {
      width: "100%",
    },
  },
  OCLTextField2: {
    marginTop: 0,
    marginBottom: 20,
  },
}));
