import React, {useEffect} from "react";
import { Box, FormControl, FormLabel } from "@material-ui/core";
import FormikControl from "../../../hooks/FormikControl";
import Question from "../../../../../images/Question.png";
import { useStyles } from "./styles";

function OfferCheckbox({ formik, TypeLenseigne, TypeOffre, typeInscrire }) {
  const classes = useStyles();

/*   useEffect(() => {
    formik.setFieldTouched("TypeLenseigne", false)
    formik.setFieldTouched("offre", false)
  }, []) */

  return (
    <Box className={classes.Container}>
      <Box className={classes.containerTE} >
        <FormikControl
          control="checkbox"
          label="Type de l'enseigne"
          name="TypeLenseigne"
          required
          options={TypeLenseigne}
          disabled={formik.isSubmitting ? true : false}
          error={
            formik.touched?.TypeLenseigne &&
            !formik?.values?.TypeLenseigne?.length
              ? true
              : false
          }
        />
        { formik.touched?.TypeLenseigne &&
          !formik?.values?.TypeLenseigne?.length ? <span style={{marginTop: 10, fontSize: "0.75rem", color: "#D94A46"}}>Le type de l'enseigne est requis</span> : null}
      
        
      </Box>

      <Box className={classes.containerTO}>
        <FormikControl
          control="checkbox"
          label="Type de l'offre"
          required
          name="offre"
          options={TypeOffre}
          disabled={formik.isSubmitting ? true : false}
          error={
            formik.touched?.offre && !formik?.values?.offre?.length
              ? true
              : false
          }
        />
        { formik.touched?.offre && !formik?.values?.offre?.length
              ? <span style={{marginTop: 10, fontSize: "0.75rem", color: "#D94A46"}}>Le type d'offre est requis</span> : null}

        <FormControl style={{ marginTop: 10 }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <FormLabel
              component="legend"
              style={{ fontWeight: "800", color: "#000" }}
            >
              Label SMR
            </FormLabel>
            <img src={Question} style={{ width: 35, height: 30 }} />
          </Box>

          <FormikControl
            control="checkbox"
            name="inscrire"
            options={typeInscrire}
            disabled={formik.isSubmitting ? true : false}
            error={
              formik.touched?.inscrire && !formik?.values?.inscrire?.length
                ? false
                : false
            }
            
          />
        </FormControl>
      </Box>
    </Box>
  );
}

export default OfferCheckbox;
