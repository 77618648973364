import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export default function ShowPasswordButton({
  handleClickShowPassword,
  handleMouseDownPassword,
  showPassword,
  disabled,
}) {
  return (
    <InputAdornment position="end">
      <IconButton
        disabled={disabled}
        aria-label="Afficher/Cacher l'ancien mot de passe"
        title="Afficher/Cacher l'ancien mot de passe"
        style={{ padding: 0 }}
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
}
