import React, { useEffect, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { colors } from "../../themes/colors";
import misterCoockyApi from "../../constante/apiUrl";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import GetAppIcon from "@material-ui/icons/GetApp";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LaunchIcon from "@material-ui/icons/Launch";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { CircularProgress } from "@material-ui/core";
import { compareAsc, format } from "date-fns";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useStyles } from "../../themes/Styles";
import Link from "@material-ui/core/Link";
import placeholderImg from "../../images/img-placeholder.png";

export default function OtherCarrierLayout() {
  const [logo, setLogo] = useState("");
  const [fileTransmited, setFileTransmited] = useState(false);
  const [carrierName, setCarrierName] = useState("");
  const [carrierLink, setCarrierLink] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const classes = useStyles();

  function logoUploaded(file) {
    let base64String = "";
    let reader = new FileReader();
    reader.onload = function () {
      base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
      setLogo(base64String);
    };
    reader.readAsDataURL(file);
  }

  function handleLogoPreview(e) {
    if (e.target.files[0]) {
      logoUploaded(e.target.files[0]);
    }
  }

  function handleFile(event) {
    const file = event.target.files[0];
    let data = new FormData();
    data.append("file", file);
    const userId = localStorage.getItem("userId");
    fetch(
      `${misterCoockyApi.misterCoockyApi}/uploadFile?sectionName=smr&userId=${userId}`,
      {
        method: "POST",
        body: data,
      }
    )
      .then((res) => res.json())
      .then(function (result) {
        setFileTransmited(true);
      })
      .catch(function (error) {
        console.log("Erreur pendant l'upload : " + error.message);
      });
  }

  const dataLogo = "data:image/png;base64," + logo;
  return (
    <div
      style={{
        width: "60%",
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div className={classes.OtherCarrierLayout}>
        <div style={{ width: 200, display: "flex", flexDirection: "column" }}>
          <span
            style={{
              fontSize: 16,
              fontWeight: 700,
              marginBottom: 20,
              textAlign: "left",
            }}
          >
            Logo
          </span>
          <img
            src={logo ? dataLogo : placeholderImg}
            width={200}
            alt="Logo du transporteur"
            style={{
              marginBottom: 20,
            }}
          ></img>
          <Button
            variant="outlined"
            style={{
              border: "2px solid #C1BFC1",
            }}
            component="label"
            size={"small"}
          >
            {logo ? "Remplacer le logo" : "Ajouter un logo"}
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleLogoPreview}
            />
          </Button>
          <span className={classes.OCLspan1}>
            Le logo apparaîtra dans la page choix du transporteur de
            l'application mobile.
          </span>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <TextField
              id="raison-sociale"
              label="Raison sociale"
              variant="outlined"
              value={carrierName}
              onChange={(e) => setCarrierName(e.target.value)}
              required
              className={classes.OCLTextField1}
            />
            <TextField
              id="lien-transporteur"
              label="Lien du transporteur"
              variant="outlined"
              value={carrierLink}
              onChange={(e) => setCarrierLink(e.target.value)}
              required
              className={[classes.OCLTextField1, classes.OCLTextField2]}
            />
          </div>

          {/*           <span
            style={{
              fontSize: 16,
              fontWeight: 700,
              marginTop: 30,
              textAlign: "left",
            }}
          >
            Grille prix/délais/poids
          </span>
          <Link
            href="#"
            style={{
              color: "#c0c0c0",
              textAlign: "left",
              marginTop: 10,
              marginBottom: 40,
            }}
          >
            Voir la grille
            <LaunchIcon
              style={{
                fontSize: 18,
                marginLeft: 5,
                padding: 0,
                marginBottom: -5,
              }}
            ></LaunchIcon>
          </Link>  */}

          <Button
            style={{
              width: "fit-content",
              marginTop: 40,
              border: "2px solid #C1BFC1",
            }}
            component="label"
          >
            Déposer votre fichier
            <input
              accept=".xlsx, .numbers, .csv"
              type="file"
              onChange={(e) => handleFile(e)}
              hidden
            />
          </Button>
          <span className={classes.OCLspan2}>
            À déposer au format .xlsx. Les prix affichés varieront selon le
            délai sélectionné par les acheteurs et le poids de l'artice.
          </span>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Checkbox style={{ marginTop: -10 }} color="#c0c0c0"></Checkbox>
        <span style={{ textAlign: "left" }}>
          Faire apparaître ce transporteur sur vos articles dans l'application
          Mister Coocky
        </span>
      </div>
      <Button
        className="default-btn"
        disabled={isAdding || !carrierName || !carrierLink ? true : false}
        style={{
          width: 200,
          fontWeight: 700,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 20,
        }}
      >
        Ajouter
      </Button>
    </div>
  );
}
