import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../themes/colors";

export const useStyles = makeStyles((theme) => ({
  Container: {
    backgroundColor: colors.whiteTr,
    width: "30vw",
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.between("xs", "md")]: {
      width: 300,
    },
  },
  ContainerButtons: {
    justifyContent: "space-between",
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  btn: {
    width: "fit-content",
    color: colors.white,
    fontWeight: "700",
    borderRadius: "10px",
  },
  ErrorMes: {
    color: colors.red,
    width: "100%",
    paddingLeft: 5,
  },
}));
