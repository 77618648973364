import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { useStyles } from "../../../hooks/Styles";
import { colors } from "../../../../../themes/colors";
import { useSelector } from "react-redux";
import { CircularProgress } from '@material-ui/core'

function Buttons({ onReturn, formik, alertOrga, checkAvailableOrganization}) {
  const classes = useStyles();
  const { isSubmitting, isValid } = formik;
  // console.log(formik.values.TypeLenseigne.length);
  // console.log(formik.values.offre.length);
  const Address = useSelector((state) => state.Address);

  const { Cordinates, Adresses } = Address;
  const Loading = () => {
    return <Box style={{display: "flex", flexDirection: "row", alignItems: "center"}}>Envoie {<CircularProgress
      color={colors.white}
      size={18}
      style={{ marginLeft: 5 }}
    />} </Box>    
  };
  return (
    <Box className={classes.BoxButtons}>
      <Button
        variant="contained"
        className="back-btn"
        disabled={formik.isSubmitting ? true : false}
        onClick={onReturn}
      >
        Précédent
      </Button>
      <Button
        type="submit"
        variant="contained"
        className="default-btn"
        disabled={formik.isSubmitting || !(formik.dirty && formik.isValid) || !(formik?.values?.TypeLenseigne?.length) || !(formik?.values?.offre?.length) || alertOrga != '' ? true : false}
      >
        {isSubmitting ? <Loading /> : "Terminer"}
      </Button>
    </Box>
  );
}

export default Buttons;
