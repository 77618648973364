import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../themes/colors";

export const useStyles = makeStyles((theme) => ({
  hdrMsg: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    color: "#bdbdbd",
    marginBottom: 5,
  },
  btn: {
    padding: theme.spacing(1),
    color: "#000",
    width: "30%",
    '&:hover': {
      backgroundColor: '#fff',
      color: '#3c52b2',
    }
  },
  formText: {
    width: "17.5vw",
    height: 50,
    padding: 0,
    [theme.breakpoints.between('xs', 'md')]: {
      width: "100%",
      marginTop: 10
    },
  },
  formTextDiv: {
    display: 'flex', flexDirection: 'column',
    marginBottom: 10,
    [theme.breakpoints.between('xs', 'md')]: {
      width: "100%",
      marginBottom: 10
    },
  },
  formControl: {
    width: 200,
    margin: theme.spacing(3),
    alignItems: "center",
    textAlign: "center",
    textJustify: "center",
  },
  ContainerForm1: {
    backgroundColor: colors.white,
    width: "40vw",
    padding: "0px 30px",
    height: "65vh",
    display: "flex",
    flexDirection: "column",
   
    paddingTop:10,
    [theme.breakpoints.between('xs', 'md')]: {
      width: "100%",
    },

  },
  containerButtons:{
    width:'100%',
    display:'flex',
    justifyContent:"space-between",
    marginTop:"15px",
    paddingBottom:30
  }
}));
