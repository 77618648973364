import { Box } from "@material-ui/core";
import React, {useState} from "react";
import { useStyles } from "../../../hooks/Styles";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

function Error({openIt, msg}) {
  const classes = useStyles();

  const [open, setOpen] = useState(openIt)

  const handleClose = () => {
    setOpen(false);
    openIt = false
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        style={{backgroundColor: "#f54257"}}
        onClick={handleClose}
      >
        <CloseIcon fontSize="small"/>
      </IconButton>
    </>

  );


  return (
    <>

    {/*<Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        action={action}
      >
        <Alert onClose={handleClose} severity="error">
          <div>
          <strong>Attention ! {`\n`}</strong>
          {msg}
          </div>

        </Alert>
    </Snackbar>*/}
      {/*<Box className={classes.errorBox}>
        <Box
          style={{
            textAlign: "center",
            fontWeight: "700",
            marginBottom: 15,
          }}
        >
          Attention
        </Box>
        <li> Les mots de passe ne correspondent pas.</li>
        <li>
          Vous devez avoir au moin un caractere alphanumérique (A-Z, 0-9).{" "}
        </li>
        <li>Votre mot de passe est trop court. </li>
      </Box>*/}
    </>
  );
}

export default Error;
