import { Box, TextField } from "@material-ui/core";
import { Field } from "formik";
import React, { useState } from "react";
import { colors } from "../../../../../themes/colors";
import { useStyles } from "./styles";
import Alert from "@material-ui/lab/Alert";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  GET_ADDRESS,
  GET_CORDINATES,
} from "../../../../../Redux/Types";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from '@material-ui/core'

function Inputs({ formik, checkAvailableOrganization, alertOrga }) {
  const classes = useStyles();
  const [inputsFormError, setInputsFormError] = useState(true)
  const [adresse, setAdresse] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [alertOrgaMsg, setAlertOrgaMsg] = useState(alertOrga)

  

  const Address = useSelector((state) => state.Address);
  const { Adresses, check } = Address;
  const dispatch = useDispatch();
  const handleAddressSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    dispatch({ type: GET_ADDRESS, payload: value });
    dispatch({ type: GET_CORDINATES, payload: latLng });
    setAdresse(value);
    setSelectedAddress(value); // update selected address value here
    formik.setFieldValue("address", value);
    console.log("adresse", Address.Adresses);
    console.log("lat : ", Address.Cordinates.lat)
    console.log("lng : ", Address.Cordinates.lng)

  };
  const AlertOrgaItem = () => {
    return alertOrgaMsg  ? (
      <Alert severity="error" fullwidth>
        {alertOrgaMsg}
      </Alert>
    ) : (
      <></>
    );
  };

  return (
    <Box className={classes.inputBox}>

      <AlertOrgaItem style={{marginBottom: 20}}/>
      <Field
        variant="outlined"
        required
        fullWidth
        id="nomentreprise"
        label="Nom de l'enseigne"
        name="nomentreprise"
        InputLabelProps={{
          margin: 'dense'
        }}
        InputProps= {{ style: {height: 40}}}
        as={TextField}
        className={classes.Field}
        onInput={() => {
            setAlertOrgaMsg("")
            formik.setFieldTouched("nomentreprise", true)}}

        disabled={formik.isSubmitting ? true : false}
        error={ formik.errors.nomentreprise && formik.touched.nomentreprise ? true : false }
        helperText={formik.errors.nomentreprise && formik.touched.nomentreprise ? formik.errors.nomentreprise : ''}
      />
        <PlacesAutocomplete
          value={formik.values.address}
          onChange={(e) => {formik.setFieldValue("address", e)}}
          onSelect={handleAddressSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => {
            return [
              <>
              {  
                
                  <Field
                    variant="outlined"
                    required
                    fullWidth
                    as={TextField}
                    InputProps= {{ style: {height: 40}}}
                    className={classes.Field}
                    id="address"
                    label="Adresse"
                    name="address"
                    onInput={() => {formik.setFieldTouched("address", true)}}
                    InputLabelProps={{
                      margin: 'dense'
                    }}
                    error={
                      formik.errors.address && formik.touched.address
                        ? true
                        : false
                    }
                    helperText={formik.errors.address && formik.touched.address ? formik.errors.address : ''}
                    {...getInputProps({
                      label: "Adresse",
                      placeholder: "Entrez l'adresse",
                      disabled: formik.isSubmitting ? true : false
                    })}
                  />
                }
                <div  style={{ position: 'absolute', 
                      border: suggestions.length > 0 || loading ? "solid 1px #DEC8A6" : "none", 
                      borderRadius: 10, overflow: "auto", zIndex: 999}}>
                  {loading ? <div style={{display: "flex", alignItems: "center"}}>
                        <CircularProgress
                          color={colors.brown}
                          size={15}
                          style={{marginRight: 10}}
                          />
                        <span> Chargement...</span>
                            </div>   : null}

                  {suggestions.map((suggestion, i) => {
                    const style = {
                      backgroundColor: suggestion.active
                        ? colors.brown
                        : "#fff",
                      color: suggestion.active ? "#fff" : "#000",
                      padding: 5,
                      zIndex: 10,
                      
                    };
                    return (
                      <div
                        key={i}
                        {...getSuggestionItemProps(suggestion, { style })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </>,
            ];
          }}
        </PlacesAutocomplete>
    </Box>
  );
}

export default Inputs;
