import React, { useEffect, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { colors } from "../../themes/colors";
import misterCoockyApi from "../../constante/apiUrl";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import GetAppIcon from "@material-ui/icons/GetApp";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { CircularProgress } from "@material-ui/core";
import { compareAsc, format } from "date-fns";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useStyles } from "../UserProfile";
import CarrierLayout from "./CarrierLayout";
import OtherCarrierLayout from "./OtherCarrierLayout";
import { useQuery } from 'react-query';
import { useQueryClient } from 'react-query';



export function useLoadCarriersList(carrierAdded) {
  const userId = localStorage.getItem("userId");
  const [refetchIndex, setRefetchIndex] = useState(0);

  const { data: globalCarriersList = [], isLoading: isLoadingGlobal, isError: globalCarriersError } = useQuery(
    ['globalCarriersList', carrierAdded, refetchIndex],
    async () => {
      const response = await fetch(`${misterCoockyApi.misterCoockyApi}/api/carriers`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      });
      return response.json();
    }
  );

  const { data: userProCarriersList = [], isLoading: isLoadingUserPro, isError: userProCarriersError } = useQuery(
    ['userProCarriersList', carrierAdded, refetchIndex],
    async () => {
      const response = await fetch(`${misterCoockyApi.misterCoockyApi}/api/carriers/user-pro/${userId}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      });
      return response.json();
    }
  );

  let carriersList = [];

  if (userProCarriersList === []) {
    carriersList = globalCarriersList;
  } else if (userProCarriersList !== []) {
    carriersList = globalCarriersList.filter((item1) => !userProCarriersList.find((item2) => item1.carrier_id === item2.carrier_id));
  }

  const isLoading = isLoadingGlobal || isLoadingUserPro;
  const isError = globalCarriersError || userProCarriersError;

  const refetchData = () => {
    setRefetchIndex((prevIndex) => prevIndex + 1);
  };

  return { carriersList, isLoading, isError, refetchData };
}




export default function AddCarriers() {
  const [chosenCarrier, setChosenCarrier] = useState("");
  const [isAddingCarrier, setIsAddingCarrier] = useState(false)
  const [carrierAdded, setCarrierAdded] = useState(false)
  const [chosenCarrierNickname, setChosenCarrierNickname] = useState("")
  const [chosenCarrierLogo, setChosenCarrierLogo] = useState("")
  const [chosenCarrierPricesLink, setChosenCarrierPricesLink] = useState("")
  const [chosenCarrierId, setChosenCarrierId] = useState('');
  const [loadingCarriers, setLoadingCarriers] = useState(true)

  const handleCarrierChange = (carrier) => {
    setChosenCarrier(carrier);
  };

  const { carriersList, isLoading, isError, refetchData } = useLoadCarriersList(false);


/*   useEffect(() => {
      loadCarriersList();
  }, [carrierAdded, !carriersLoaded]);
  
  const loadCarriersList = async () => {
    try {
      const globalResponse = await fetch(`${misterCoockyApi.misterCoockyApi}/api/carriers`, {
        method: "GET",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          "Content-Type": "application/json",
        },
      });
      const globalResult = await globalResponse.json();
      console.log("global carriers : " + JSON.stringify(globalResult));
      setGlobalCarriersList(globalResult);
  
      const userResponse = await fetch(`${misterCoockyApi.misterCoockyApi}/api/carriers/user-pro/`+userId, {
        method: "GET",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          "Content-Type": "application/json",
        },
      });
      const userResult = await userResponse.json();
      setUserProCarriersList(userResult);
  
      if (userProCarriersList === []) {
        setCarriersList(globalCarriersList);
      } else if (userProCarriersList !== []) {
        const filteredCarriersList = globalCarriersList.filter(item1 => !userProCarriersList.find(item2 => item1.carrier_id === item2.carrier_id));
        setCarriersList(filteredCarriersList);
      }
  
      setCarriersLoaded(true);
    } catch (error) {
      console.log(error);
    }
  }; */


  



  const addCarrier = (carrierId) => {
    fetch(`${misterCoockyApi.misterCoockyApi}/api/user-pros/`+localStorage.getItem("userId")+`/add-carriers`, {
      method: "PUT",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        "Content-Type": "application/json",
      },
      body: JSON.stringify([carrierId])
    })
      .then((res) => res.json())
      .then(function (result) {
        setChosenCarrier("")
        setCarrierAdded(true)
        refetchData()
        if (isLoading === false) {
          setIsAddingCarrier(false)
        }
      })

  }



  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        alignContent: "center",
        width: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        height: "fit-content",
      }}
    >
      {/*<span>Loading state : {isLoading.toString()}</span>*/}
      <Box
        style={{
          width: "100%",
          fontSize: 20,
          fontWeight: 700,
          marginBottom: 20,
        }}
      >
        Sélectionnez un transporteur parmi la liste déroulante
      </Box>

        <FormControl
          variant="outlined"
          required={true}
          style={{
            width: "60%",
            height: 20,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 40,
          }}
        >
            <InputLabel id="select-transporteurs">Transporteurs</InputLabel>
            <Select
              style={{ textAlign: "left", marginRight: 10}}
              labelId="select-transporteurs"
              id="select-transporteurs"
              label="Transporteurs"
              value={chosenCarrierId}
              disabled={isAddingCarrier}
              onChange={(e) => {
                const selectedCarrierId = e.target.value;
                const selectedCarrier = carriersList.find(carrier => carrier.carrier_id === selectedCarrierId);
                if (selectedCarrier) {
                  handleCarrierChange(selectedCarrier.carrier_id);
                  setChosenCarrierId(selectedCarrier.carrier_id);
                  setChosenCarrierNickname(selectedCarrier.nickname);
                  setChosenCarrierLogo(selectedCarrier.carrier_logo);
                  setChosenCarrierPricesLink(selectedCarrier.carrier_price_list_link);
                }
              }}
            >
              {isLoading === false ?  
                carriersList.map((carrier) => {
                  return (
                    <MenuItem key={carrier.carrier_id} value={carrier.carrier_id}>
                      {carrier.nickname}
                    </MenuItem>
                  );
                }) : <div style={{height:"fit-content"}}>
                  <MenuItem disabled>
                    <CircularProgress style={{margin: "0 auto"}} color={colors.brown} size={30} />
                  </MenuItem>
                </div> }
            </Select>
        </FormControl>
        


      {chosenCarrier !== "" ? 
      <CarrierLayout
          carrierLogo={chosenCarrierLogo}
          carrierPricesLink={chosenCarrierPricesLink}
      ></CarrierLayout> : null}
      {chosenCarrier === "autre transporteur" ? (
        <OtherCarrierLayout></OtherCarrierLayout>
      ) : null}
        <Button
          onClick={()=>{
            setIsAddingCarrier(true)
            console.log(chosenCarrier)
            addCarrier(chosenCarrier)
          }}
          className="default-btn"
          disabled={!chosenCarrier || isAddingCarrier}
          style={{
            width: 200,
            fontWeight: 700,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
          }}
        >
          Ajouter {isAddingCarrier ? 
                <CircularProgress
                color={"#fff"}
                size={18}
                style={{marginLeft: 10}}
                /> : null}
        </Button>
        <Snackbar
                  open={carrierAdded}
                  autoHideDuration={4000}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  onClose={()=> setCarrierAdded(false)}
                  >
           <Alert severity="success">Transporteur ajouté</Alert>
        </Snackbar>
    </Container>
  );
}
