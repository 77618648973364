import React, { useEffect, useState, useRef } from "react";
import { Header, Title } from "../components";
import ShowPasswordButton from "../components/ShowPasswordButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { colors } from "../themes/colors";
import misterCoockyApi from "../constante/apiUrl";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { CircularProgress } from "@material-ui/core";
import { compareAsc, format } from "date-fns";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { ValidationSchemas } from "./ValidationSchemas";
import Carriers from "./Carriers/Index";
import { fr } from "yup-locales";
import { setLocale } from "yup";
import { useStyles } from "../themes/Styles";

setLocale(fr);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      maxWidth: 50,
    },
  },
  // Show dropdow at bottom of select
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

function UserProfile() {
  const {
    NomEntrepriseSchema,
    AdresseSchema,
    TypeLenseigneSchema,
    OffresSchema,
    NomSchema,
    PrenomSchema,
    EmailSchema,
    PhoneSchema,
    OldPasswordSchema,
    PasswordSchema,
    CfPasswordSchema,
  } = ValidationSchemas();

  const [pageId, setPageId] = useState(0);
  const [gotInfo, setGotInfo] = useState(false);
  const myContainer = useRef(null);
  const [dataSaved, setDataSaved] = useState(false);
  const [pwDataSaved, setPwDataSaved] = useState(false);
  const [alert, setAlert] = useState(""); //message d'alerte au niveau du mot de passe
  const [alertMail, setAlertMail] = useState(""); //message d'alerte au niveau de l'adresse mail
  const [alertOrganization, setAlertOrganization] = useState(""); //message d'alerte au niveau de l'organisation
  const [submited, setSubmited] = useState(false);
  const [errorPassMsg, setErrorPassMsg] = useState("");
  const [errorOldPassMsg, setErrorOldPassMsg] = useState("");
  const [errorPass1Msg, setErrorPass1Msg] = useState("");
  const [userPro, setUserPro] = useState("");
  const [nomOrga, setNomOrga] = useState("");
  const [nomOrgaInitial, setNomOrgaInitial] = useState("");
  const [nomOrgaError, setNomOrgaError] = useState("");
  const [nomFournisseur, setNomFournisseur] = useState("");
  const [adresse, setAdresse] = useState("");
  const [adressError, setAdressError] = useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: 0,
    lng: 0,
  });
  const [validAdresse, setValidAdresse] = useState(true);
  const [typesOrga, setTypesOrga] = useState();
  const [typeOrgaError, setTypeOrgaError] = useState("");
  const [typesOffres, setTypesOffres] = useState();
  const [typeOffresError, setTypeOffresError] = useState("");
  const [estArtisan, setEstArtisan] = useState(false);
  const [estMagasinSpe, setEstMagasinSpe] = useState(false);
  const [estMagasinGen, setEstMagasinGen] = useState(false);
  const [estAsso, setEstAsso] = useState(false);
  const [estMarque, setEstMarque] = useState(false);

  const [postImage, setPostImage] = useState({
    myFile: "",
  });
  const [estAlim, setEstAlim] = useState(false);
  const [estAccess, setEstAccess] = useState(false);
  const [nomsServicesCHoisis, setNomsServicesCHoisis] = useState(false);
  const nomsServices = ["Cession d'animaux(don, échange, vente)"];
  const [nomUser, setNomUser] = useState("");
  const [nomUserError, setNomUserError] = useState("");
  const [prenomUser, setPrenomUser] = useState("");
  const [prenomUserError, setPrenomUserError] = useState("");
  const [postalAddress, setPostalAdress] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [initialEmail, setInitialEmail] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [isSavingPw, setIsSavingPw] = useState(false);
  const [profileFormErrors, setProfileFormErrors] = useState(false);
  const [profileErrMsg, setProfileErrMsg] = useState("");
  const [editPwFormErrors, setEditPwFormErrors] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  let formData = new FormData();
  const classes = useStyles();

  

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const checkOrga = () => {
    NomEntrepriseSchema.validate({ nomentreprise: nomOrga })
      .then(() => {
        setNomOrgaError("");
      })
      .catch((err) => {
        setNomOrgaError(err.errors);
      });
  };

  const checkAdresse = () => {
    AdresseSchema.validate({ adress: adresse })
      .then(() => {
        setAdressError("");
      })
      .catch((err) => {
        setAdressError(err.errors);
      });
  };

  const checkTE = () => {
    if (
      estArtisan === false &&
      estMagasinSpe === false &&
      estMagasinGen === false &&
      estAsso === false &&
      estMarque === false
    ) {
      setTypeOrgaError("Le type de l'enseigne est requis");
    } else if (typesOrga !== undefined) {
      setTypeOrgaError("");
    } else {
      setTypeOrgaError("");
    }
  };

  useEffect(() => {
    checkTE();
  }, [estArtisan, estMagasinSpe, estMagasinGen, estAsso, estMarque]);

  const checkTO = () => {
    if (
      estAlim === false &&
      estAccess === false &&
      nomsServicesCHoisis === false
    ) {
      setTypeOffresError("Le type d'offres est requis");
    } else if (typesOffres != undefined) {
      setTypeOffresError("");
    } else {
      setTypeOffresError("");
    }
  };

  useEffect(() => {
    checkTO();
  }, [estAlim, estAccess, nomsServicesCHoisis]);

  const checkNom = () => {
    NomSchema.validate({ nom: nomUser })
      .then(() => {
        setNomUserError("");
      })
      .catch((err) => {
        setNomUserError(err.errors);
      });
  };

  const checkPrenom = () => {
    PrenomSchema.validate({ prenom: prenomUser })
      .then(() => {
        setPrenomUserError("");
      })
      .catch((err) => {
        setPrenomUserError(err.errors);
      });
  };

  const checkEmail = () => {
    EmailSchema.validate({ email: email })
      .then(() => {
        setEmailError("");
      })
      .catch((err) => {
        setEmailError(err.errors);
      });
  };

  const checkPhone = () => {
    PhoneSchema.validate({ phone: phone })
      .then(() => {
        setPhoneError("");
      })
      .catch((err) => {
        setPhoneError(err.errors);
      });
  };

  const checkOldPassword = () => {
    OldPasswordSchema.validate({ oldPassword: oldPassword })
      .then(() => {
        setErrorOldPassMsg("");
      })
      .catch((err) => {
        setErrorOldPassMsg(err.errors);
      });
  };

  const checkPassword = () => {
    if (password1 != password2) {
      setErrorPassMsg("Les mots de passe ne correspondent pas");
    }
    PasswordSchema.validate({ password: password1 })
      .then(() => {
        setErrorPass1Msg("");
      })
      .catch((err) => {
        setErrorPass1Msg(err.errors);
      });
  };

  const checkCfPassword = () => {
    if (password1 === password2) {
      setErrorPassMsg("");
    } else {
      CfPasswordSchema.validate({ cfpassword: password2 })
        .then(() => {
          setErrorPassMsg("");
        })
        .catch((err) => {
          setErrorPassMsg(err.errors);
        });
    }
  };

  useEffect(() => {
    checkOldPassword();
    checkPassword();
    checkCfPassword();
  }, [oldPassword]);

  useEffect(() => {
    checkOldPassword();
    checkPassword();
    checkCfPassword();
  }, [password1]);

  useEffect(() => {
    checkOldPassword();
    checkPassword();
    checkCfPassword();
  }, [password2]);

  useEffect(() => {
    checkEditPasswordFormErrors();
  }, [errorOldPassMsg, errorPass1Msg, errorPassMsg]);

  const checkEditProfileForm = () => {
    checkOrga();
    checkAdresse();
    checkTE();
    checkTO();
    checkNom();
    checkPrenom();
    checkEmail();
    checkPhone();
  };

  useEffect(() => {
    checkEditProfileForm();
  }, [
    nomOrga,
    adresse,
    nomUser,
    prenomUser,
    email,
    phone,
    typesOrga,
    typesOffres,
  ]);

  useEffect(() => {
    checkEditProfileFormErrors();
  }, [
    nomOrgaError,
    adressError,
    typeOrgaError,
    typeOffresError,
    nomUserError,
    prenomUserError,
    emailError,
    phoneError,
  ]);

  const checkEditProfileFormErrors = () => {
    if (
      nomOrgaError === "" &&
      adressError === "" &&
      typeOrgaError === "" &&
      typeOffresError === "" &&
      nomUserError === "" &&
      prenomUserError === "" &&
      emailError === "" &&
      phoneError === ""
    ) {
      setProfileErrMsg("");
      setProfileFormErrors(false);
    } else {
      setProfileFormErrors(true);
      setProfileErrMsg("Un ou plusieurs champs sont vides ou invalides");
    }
  };

  const checkEditPasswordFormErrors = () => {
    if (
      errorOldPassMsg === "" &&
      oldPassword != 0 &&
      errorPass1Msg === "" &&
      password1.length != 0 &&
      errorPassMsg === "" &&
      password2.length != 0 &&
      password1 === password2
    ) {
      setEditPwFormErrors(true);
    } else if (
      errorOldPassMsg != "" ||
      errorPass1Msg != "" ||
      errorPassMsg != ""
    ) {
      setEditPwFormErrors(false);
    }
  };

  function getUserProfileData() {
    fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
                    query{
                        userPro(id:"${localStorage.getItem("userId")}"){
                          smrRequest {
                            id,
                            firstStepStatus,
                            firstStepDate,
                            secondStepStatus,
                            secondStepDate,
                            thirdStepStatus,
                            thirdStepDate
                        }
                            organization{
                                name
                                logo
                                supplier{
                                    name
                                }
                               
                                postalAddress{
                                    street
                                    geoLocation{
                                        latitude
                                        longitude
                                    }
                                }
                            }
                            user{
                                lastName
                                firstName
                                email
                                phoneNumber
                            }
                        }
                    }`,
      }),
    })
      .then((res) => res.json())
      .then(function (result) {
        console.log(result)
        setUserPro(result.data.userPro);
        setNomOrga(result.data.userPro.organization.name);
        setNomOrgaInitial(result.data.userPro.organization.name);
        setPostImage(result.data.userPro.organization.logo);
        setAdresse(result.data.userPro.organization.postalAddress.street);
        setCoordinates({
          lat: result.data.userPro.organization.postalAddress.geoLocation
            .latitude,
          lng: result.data.userPro.organization.postalAddress.geoLocation
            .longitude,
        });
        setNomFournisseur(result.data.userPro.organization.supplier.name);
        setNomUser(result.data.userPro.user.firstName);
        setPrenomUser(result.data.userPro.user.lastName);
        setPhone(result.data.userPro.user.phoneNumber);
        setPostalAdress(result.data.userPro.organization.postalAddress);
        setInitialEmail(result.data.userPro.user.email);
        setEmail(result.data.userPro.user.email);
        fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
          method: "POST",
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
                            query{
                                getTypesOrganizationByName(name: "${result.data.userPro.organization.name}")
                            }`,
          }),
        })
          .then((res) => res.json())
          .then(function (result) {
            result.data.getTypesOrganizationByName.forEach((type) => {
              if (type === "Artisan") {
                setEstArtisan(true);
              }
              if (type === "Magasin spécialisé") {
                setEstMagasinSpe(true);
              }
              if (type === "Magasin généraliste") {
                setEstMagasinGen(true);
              }
              if (type === "Association") {
                setEstAsso(true);
              }
              if (type === "Marque") {
                setEstMarque(true);
              }
            });
            setTypesOrga(result.data.getTypesOrganizationByName.join(", "));
          });
        fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
          method: "POST",
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
                                query{
                                    getOffersOrganizationByName(name: "${result.data.userPro.organization.name}")
                                }`,
          }),
        })
          .then((res) => res.json())
          .then(function (result) {
            result.data.getOffersOrganizationByName.forEach((type) => {
              if (type === "Alimentation") {
                setEstAlim(true);
              }
              if (type === "Accessoire") {
                setEstAccess(true);
              }
              if (type === "Services") {
                setNomsServicesCHoisis(true);
              }
            });
            setTypesOffres(result.data.getOffersOrganizationByName.join(", "));
            setGotInfo(true);
          });
      });
    checkEditProfileForm();
  }

  useEffect(() => {
    getUserProfileData();
  }, []);

  const handleAddressSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAdresse(value);
    setCoordinates(latLng);
    setValidAdresse(true);
    console.log("adresse : " + adresse)
    console.log("latLng: " + latLng.lat + ", " + latLng.lng)
  };

  function checkAvailableOrganization(orga) {
    fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
                    query{
                        existsOrganizationByName(name:"${orga}")
                    }`,
      }),
    })
      .then((res) => res.json())
      .then(function (result) {
        if (
          result.data.existsOrganizationByName === true &&
          nomOrgaInitial !== nomOrga
        ) {
          setAlertOrganization("Nom d'organisation déjà utilisé!");
        } else if (result.data.existsOrganizationByName === false) {
          setAlertOrganization("");
        }
      });
  }
  function checkAvailableAdress(mail) {
    fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
                    query{
                        getMail(mail:"${mail}"){
                            id
                        }
                    }`,
      }),
    })
      .then((res) => res.json())
      .then(function (result) {
        if (result.data.getMail !== null && email !== initialEmail) {
          setAlertMail("Email déjà utilisée !");
        } else if (result.data.getMail !== null) {
          setAlertMail("");
        }
      });
  }
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setPostImage({ ...postImage, myFile: base64 });
  };

  function handleAsk() {
    fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
          mutation ($id: String!) {
            sendSmrRequest(id: $id) {
                id,
                smrSubscription,
                smrRequest {
                    id,firstStepStatus,firstStepDate,secondStepStatus,secondStepDate,thirdStepStatus,thirdStepDate
                },
                user {
                    id,
                    firstName,
                    lastName
                },
                organization {
                    id,
                    logo,
                    name
                }
              }
            }
          `,
        variables: {
          id: localStorage.getItem("userId"),
        },
      }),
    }).then((res) => {
      getUserProfileData();
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    checkAvailableAdress(email);
    checkEditProfileForm();
    if (profileErrMsg === "") {
      if (
        validAdresse === true &&
        alertOrganization === "" &&
        alertMail === ""
      ) {
        setIsSavingProfile(true);
        setSubmited(true);
        let typesOrga = [];
        if (estArtisan === true) {
          typesOrga.push("Artisan");
        }
        if (estMagasinSpe === true) {
          typesOrga.push("Magasin spécialisé");
        }
        if (estMagasinGen === true) {
          typesOrga.push("Magasin généraliste");
        }
        if (estAsso === true) {
          typesOrga.push("Association");
        }
        if (estMarque === true) {
          typesOrga.push("Marque");
        }
        let typeOffre = [];
        if (estAlim === true) {
          typeOffre.push("Alimentation");
        }
        if (estAccess === true) {
          typeOffre.push("Accessoire");
        }
        if (nomsServicesCHoisis === true) {
          typeOffre.push("Services");
        }
        fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
          method: "POST",
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
                              mutation($userProId: String, $organization: OrganizationInput!, $adress:AdressInput!, $user:UserIntput!){
                                  updateUserPro(userProId: $userProId, organization: $organization,  adress:$adress, user:$user)
                                  {
                                      id
                                      organization {
                                        id,
                                        name,
                                        logo
                                    }
                                      user{
                                          firstName
                                          lastName
                                      }
                                  }
                              }`,
            variables: {
              userProId: localStorage.getItem("userId"),
              organization: {
                name: nomOrga,
                logo: postImage?.myFile || postImage,
                orgas: typesOrga,
                offers: typeOffre,
              },
              adress: {
                street: adresse,
                geoLocation: {
                  latitude: coordinates.lat,
                  longitude: coordinates.lng,
                },
              },
              user: {
                lastName: prenomUser,
                firstName: nomUser,
                email: email,
                phone: phone,
                password: "",
              },
            },
          }),
        })
          .then((res) => res.json())
          .then(function (result) {
            if (result.data.saveUserPro !== []) {
              setDataSaved(true);
              setTimeout(() => {
                setIsSavingProfile(false);
                setPageId(0);
                setDataSaved(false);
                getUserProfileData();
              }, 3000);
            }
          });
      }
    }
  }

  function handleSubmitPassword(event) {
    event.preventDefault();
    if (password1.length >= 4 && password1 === password2) {
      setAlert("");
      setIsSavingPw(true);
      fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
        method: "POST",
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
                        query{
                            matchPassword(password:"${oldPassword}", userProId: "${localStorage.getItem(
            "userId"
          )}")
                        }
                    `,
        }),
      })
        .then((res) => res.json())
        .then(function (result) {
          if (result.data.matchPassword === true) {
            if (
              validAdresse === true &&
              alertOrganization === "" &&
              alertMail === ""
            ) {
              setSubmited(true);
              let typesOrga = [];
              if (estArtisan === true) {
                typesOrga.push("Artisan");
              }
              if (estMagasinSpe === true) {
                typesOrga.push("Magasin spécialisé");
              }
              if (estMagasinGen === true) {
                typesOrga.push("Magasin généraliste");
              }
              if (estAsso === true) {
                typesOrga.push("Association");
              }
              if (estMarque === true) {
                typesOrga.push("Marque");
              }
              let typeOffre = [];
              if (estAlim === true) {
                typeOffre.push("Alimentation");
              }
              if (estAccess === true) {
                typeOffre.push("Accessoire");
              }
              if (nomsServicesCHoisis === true) {
                typeOffre.push("Services");
              }

              fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
                method: "POST",
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  query: `
                        mutation($userProId: String, $organization: OrganizationInput!, $adress:AdressInput!, $user:UserIntput!){
                            updateUserPro(userProId: $userProId, organization: $organization,  adress:$adress, user:$user)
                            {
                                id
                                organization{
                                  id,                                   
                                    name,
                                    logo
                                }
                                user{
                                    name
                                }
                            }
                        }`,
                  variables: {
                    userProId: localStorage.getItem("userId"),
                    organization: {
                      name: nomOrga,
                      logo: postImage?.myFile || postImage,
                      orgas: typesOrga,
                      offers: typeOffre,
                    },
                    adress: {
                      street: adresse,
                      geoLocation: {
                        latitude: coordinates.lat,
                        longitude: coordinates.lng,
                      },
                    },
                    user: {
                      lastName: prenomUser,
                      firstName: nomUser,
                      email: email,
                      phone: phone,
                      password: password1,
                    },
                  },
                }),
              })
                .then((res) => res.json())
                .then(function (result) {
                  if (result.data.saveUserPro !== []) {
                    //setPassword1("")
                    //setPassword2("")
                    //setErrorOldPassMsg("")
                    //setErrorPass1Msg("")
                    //setErrorPassMsg("")
                    setPwDataSaved(true);
                    setTimeout(() => {
                      setPageId(0);
                      setOldPassword("");
                      setPassword1("");
                      setPassword2("");
                      setIsSavingPw(false);
                      setPwDataSaved(false);
                      getUserProfileData();
                    }, 3000);
                  }
                });
            }
          } else {
            setAlert("Ancien mot de passe incorrect");
            setIsSavingPw(false);
            setErrorOldPassMsg("Ancien mot de passe incorrect");
          }
        });
    } else if (password1.length < 4) {
      setAlert("La longueur des mots de passe doit excéder 4 caractères");
      setIsSavingPw(false);
    } else if (password1 !== password2) {
      setAlert("Mots de passe différents.");
      setIsSavingPw(false);
    }
  }

  function getPageContent() {
    switch (pageId) {
      default:
      case 0:
        return (
          <Box mt={10}>
            {userPro ? (
              <div>
                <Container style={{ paddingTop: 5 }}>
                  <h3 style={{}}>Organisation</h3>
                  <Box ml={3}>
                    <Grid container className={classes.grid}>
                      <Grid item xs={6} sm={3} className={classes.boldFields}>
                        Nom
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        {userPro.organization.name}
                      </Grid>
                    </Grid>
                    <Grid container className={classes.grid}>
                      <Grid item xs={6} sm={3} className={classes.boldFields}>
                        Types d'organisation
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        {typesOrga}
                      </Grid>
                    </Grid>
                    <Grid container className={classes.grid}>
                      <Grid item xs={6} sm={3} className={classes.boldFields}>
                        Offres
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        {typesOffres}
                      </Grid>
                    </Grid>
                    <Grid container className={classes.grid}>
                      <Grid item xs={6} sm={3} className={classes.boldFields}>
                        Adresse Postale
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        {userPro.organization.postalAddress.street}
                      </Grid>
                    </Grid>
                  </Box>
                  <h3>Utilisateur</h3>
                  <Box ml={3}>
                    <Grid container className={classes.grid}>
                      <Grid item xs={6} sm={3} className={classes.boldFields}>
                        Nom & Prénom
                      </Grid>
                      <Grid style={{ width: "fit-content" }} item xs={6} sm={3}>
                        {userPro.user.firstName} {userPro.user.lastName}
                      </Grid>
                    </Grid>
                    <Grid container className={classes.grid}>
                      <Grid item xs={6} sm={3} className={classes.boldFields}>
                        Téléphone
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        {userPro.user.phoneNumber}
                      </Grid>
                    </Grid>
                    <Grid container className={classes.grid}>
                      <Grid item xs={6} sm={3} className={classes.boldFields}>
                        Email
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        {userPro.user.email}
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider style={{ marginTop: 20 }} />
                  <h3
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginLeft: 10,
                    }}
                  >
                    Éligibilité au label SMR
                  </h3>
                  {userPro.smrRequest && (
                    <Box ml={3} className={classes.SMRpendingcontainer}>
                      <Grid
                        container
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "nowrap",
                          position: "relative",
                        }}
                        className={classes.grid}
                      >
                        <span>
                          <span style={{ fontWeight: 700 }}>
                            {" "}
                            Numéro de la demande :{" "}
                          </span>{" "}
                          {userPro.smrRequest.id}
                        </span>
                      </Grid>
                      <Grid container className={classes.grid}>
                        <span>
                          <span style={{ fontWeight: 700 }}>
                            État de la demande :{" "}
                          </span>
                          En cours{" "}
                          {userPro.smrRequest.firstStepDate &&
                            "(" +
                              format(
                                new Date(userPro.smrRequest?.firstStepDate),
                                "yyyy-MM-dd"
                              ) +
                              ")"}
                        </span>
                      </Grid>
                      <div
                        style={{
                          position: "relative",
                          left: "10%",
                          marginTop: 80,
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:
                              userPro.smrRequest.firstStepStatus === "PENDING"
                                ? "orange"
                                : "#7AAD79",
                          }}
                          className={classes.SMRpendingfirstpt}
                        >
                          <span className={classes.SMRpendingLabel1}>
                            {userPro.smrRequest.firstStepStatus === "PENDING"
                              ? "En cours"
                              : "Valide"}
                          </span>
                          <span className={classes.SMRpendingLabel2}>
                            {userPro.smrRequest.firstStepDate &&
                              "(" +
                                format(
                                  new Date(userPro.smrRequest?.firstStepDate),
                                  "dd-MM-yyyy"
                                ) +
                                ")"}
                          </span>
                          <span className={classes.SMRpendingLabel3}>
                            Respect du cahier des charges
                          </span>
                        </div>
                        <div
                          style={{
                            backgroundColor:
                              userPro.smrRequest.secondStepStatus === null
                                ? "orange"
                                : "#D94A46",
                          }}
                          className={classes.SMRpendingsecondpt}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "-150%",
                              left: "-10%",
                              fontWeight: 600,
                            }}
                          ></span>
                          <span className={classes.SMRpendingLabel4}>
                            {userPro.smrRequest.secondStepDate &&
                              "(" +
                                format(
                                  new Date(userPro.smrRequest?.secondStepDate),
                                  "dd-MM-yyyy"
                                ) +
                                ")"}
                          </span>
                          <span className={classes.SMRpendingLabel5}>
                            Entretien avec {"\n"}l’équipe Mister Coocky
                          </span>
                        </div>
                        <div
                          style={{
                            backgroundColor:
                              userPro.smrRequest.thirdStepStatus === null
                                ? "orange"
                                : "#C1BFC1",
                          }}
                          className={classes.SMRpendingthirdpt}
                        >
                          <span
                            style={{
                              position: "absolute",
                              top: "-150%",
                              left: "-10%",
                              fontWeight: 600,
                            }}
                          ></span>
                          <span className={classes.SMRpendingLabel6}>
                            {userPro.smrRequest.thirdStepDate &&
                              "(" +
                                format(
                                  new Date(userPro.smrRequest?.thirdStepDate),
                                  "dd-MM-yyyy"
                                ) +
                                ")"}
                          </span>
                          <span className={classes.SMRpendingLabel7}>
                            Obtention {"\n"}du label SMR
                          </span>
                        </div>

                        <div className={classes.SMRprogressbar}></div>
                      </div>
                    </Box>
                  )}
                  {!userPro.smrRequest && (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "50px",
                      }}
                      ml={3}
                    >
                      <img
                        src={require("../images/smr.png").default}
                        alt="SMR"
                        width="130"
                        height="130"
                      />
                      <Grid
                        style={{
                          textAlign: "center",
                          minWidth: "100%",
                          marginTop: 20,
                        }}
                        item
                        xs={12}
                        sm={3}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            backgroundColor: colors.red,
                            color: "#ffffff",
                          }}
                          fullwidth
                          onClick={handleAsk}
                          className={classes.btnAsk}
                        >
                          Faire la demande
                        </Button>
                      </Grid>
                    </Box>
                  )}
                </Container>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <CircularProgress
                  color={colors.brown}
                  size={108}
                  className={classes.spinner}
                />
              </div>
            )}
          </Box>
        );
        break;
      case 1:
        return (
          <Box mt={10}>
            <form className={classes.form} onSubmit={handleSubmit}>
              {
                <Snackbar
                  open={dataSaved}
                  autoHideDuration={6000}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  onClose={() => setDataSaved(false)}
                >
                  <Alert severity="success">Informations enrengistrées</Alert>
                </Snackbar>
              }
              <Grid className={classes.form} container spacing={1}>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <Box className={classes.editProfRequiredLabel}>
                    {" "}
                    Champs obligatoires
                    <Box
                      style={{ color: "#D94A46", marginLeft: 3, fontSize: 20 }}
                    >
                      {" "}
                      *{" "}
                    </Box>
                  </Box>
                  <h3 className={classes.orgaSectionLabel}>Organisation</h3>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    required={true}
                    spacing={3}
                  >
                    <TextField
                      className={classes.formControlO}
                      id="nomOrga"
                      label="Nom de l'enseigne"
                      value={nomOrga}
                      required
                      onChange={function (e) {
                        setNomOrga(e.target.value);
                        checkOrga();
                      }}
                      onBlur={() => checkAvailableOrganization(nomOrga)}
                      variant="outlined"
                      error={nomOrgaError != "" ? true : false}
                      helperText={nomOrgaError}
                      disabled={isSavingProfile ? true : false}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    required={true}
                    spacing={3}
                  >
                    {/* <TextField
                      className={classes.formControlO}
                      id="adresseOrg"
                      label="Adresse"
                      value={adresse}
                      required
                      onChange={function (e) {
                        setAdresse(e.target.value);
                        checkAdresse();
                      }}
                      variant="outlined"
                      error={adressError != "" ? true : false}
                      helperText={adressError}
                      disabled={isSavingProfile ? true : false}
                    /> */}
                    <PlacesAutocomplete
                      value={adresse}
                      onChange={(e) => {
                        setAdresse(e);
                        checkAdresse();
                      }}
                      onSelect={handleAddressSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => {
                        return [
                          <>
                          {  
                              <TextField
                              className={classes.formControlO}
                              style={{display: "relative"}}
                              id="adresseOrg"
                              label="Adresse"
                              required
                              variant="outlined"
                              error={adressError !== "" ? true : false}
                              helperText={adressError}
                              {...getInputProps({
                                label: "Adresse",
                                placeholder: "Entrez l'adresse",
                                disabled: isSavingProfile ? true : false
                              })}
                              />
                            }
                            <div  style={{ position: 'absolute', 
                                  top: "8vh",
                                  left: "2vw",
                                  border: suggestions.length > 0 || loading ? "solid 1px #DEC8A6" : "none", 
                                  borderRadius: 10, overflow: "auto", zIndex: 999}}>
                              {loading ? <div style={{display: "flex", alignItems: "center"}}>
                                    <CircularProgress
                                      color={colors.brown}
                                      size={15}
                                      style={{marginRight: 10}}
                                      />
                                    <span> Chargement...</span>
                                        </div>   : null}

                              {suggestions.map((suggestion, i) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? colors.brown
                                    : "#fff",
                                  color: suggestion.active ? "#fff" : "#000",
                                  padding: 5,
                                  zIndex: 10,
                                  
                                };
                                return (
                                  <div
                                    key={i}
                                    {...getSuggestionItemProps(suggestion, { style })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </>,
                        ];
                      }}
                    </PlacesAutocomplete>
                  </FormControl>
                  <Grid className={classes.gridCheck} item xs={12} sm={8}>
                    <FormControl
                      component="fieldset"
                      required={true}
                      className={classes.formControlTO}
                      onChange={() => {
                        checkTE();
                      }}
                      error={typeOrgaError !== "" ? true : false}
                      formHelperText={typeOrgaError}
                      disabled={isSavingProfile ? true : false}
                    >
                      <FormLabel
                        className={classes.formLabelTO}
                        component="legend"
                      >
                        Type de l'enseigne
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={estArtisan}
                              onChange={(e) => setEstArtisan(e.target.checked)}
                              name="estArtisan"
                              color="default"
                              disabled={isSavingProfile ? true : false}
                            />
                          }
                          label="Artisan"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={estMagasinSpe}
                              onChange={(e) =>
                                setEstMagasinSpe(e.target.checked)
                              }
                              name="estMagasinSpe"
                              color="default"
                              disabled={isSavingProfile ? true : false}
                            />
                          }
                          label="Magasin spécialisé"
                          className={classes.formControlLabelTE}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={estMagasinGen}
                              onChange={(e) =>
                                setEstMagasinGen(e.target.checked)
                              }
                              name="estMagasinGen"
                              color="default"
                              disabled={isSavingProfile ? true : false}
                            />
                          }
                          label="Magasin généraliste"
                          className={classes.formControlLabelTE}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={estAsso}
                              onChange={(e) => setEstAsso(e.target.checked)}
                              name="estAsso"
                              color="default"
                              disabled={isSavingProfile ? true : false}
                            />
                          }
                          label="Association"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={estMarque}
                              onChange={(e) => setEstMarque(e.target.checked)}
                              name="estMarque"
                              color="default"
                              disabled={isSavingProfile ? true : false}
                            />
                          }
                          label="Marque"
                        />
                      </FormGroup>
                      <span
                        style={{
                          marginTop: 10,
                          fontSize: "0.75rem",
                          color: "#D94A46",
                        }}
                      >
                        {typeOrgaError}
                      </span>
                    </FormControl>
                    <FormControl
                      required
                      component="fieldset"
                      className={classes.formControlTO}
                      onChange={() => {
                        checkTO();
                      }}
                      error={typeOffresError !== "" ? true : false}
                      formHelperText={typeOffresError}
                      disabled={isSavingProfile ? true : false}
                    >
                      <FormLabel
                        className={classes.formLabelTO}
                        component="legend"
                      >
                        Type de l'offre
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={estAlim}
                              onChange={(e) => setEstAlim(e.target.checked)}
                              name="Alimentation"
                              color="default"
                              disabled={isSavingProfile ? true : false}
                            />
                          }
                          label="Alimentation"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={estAccess}
                              onChange={(e) => setEstAccess(e.target.checked)}
                              name="Accessoire"
                              color="default"
                              disabled={isSavingProfile ? true : false}
                            />
                          }
                          label="Accessoire"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={nomsServicesCHoisis}
                              onChange={(e) =>
                                setNomsServicesCHoisis(e.target.checked)
                              }
                              name="Services"
                              color="default"
                              disabled={isSavingProfile ? true : false}
                            />
                          }
                          label="Services"
                        />
                        <span
                          style={{
                            marginTop: 10,
                            fontSize: "0.75rem",
                            color: "#D94A46",
                          }}
                        >
                          {typeOffresError}
                        </span>
                        {/* <FormControl>
                        <InputLabel>Services</InputLabel>
                        <Select
                          multiple
                          value={nomsServicesCHoisis}
                          onChange={handleChange}
                          input={<Input id="select-multiple-checkbox" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {nomsServices.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={nomsServicesCHoisis.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    className={classes.userLabelContainer}
                    style={{
                      textAlign: "left",
                      marginLeft: 25,
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <h3 className={classes.userSectionLabel}>Utilisateur</h3>
                    <Box className={classes.userRequiredLabel}>
                      {" "}
                      Champs obligatoires
                      <Box
                        style={{
                          color: "#D94A46",
                          marginLeft: 3,
                          fontSize: 20,
                        }}
                      >
                        {" "}
                        *{" "}
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.formControlNameContainer}>
                    <FormControl variant="outlined" required={true} spacing={3}>
                      <TextField
                        className={classes.formControlName}
                        id="nomUser"
                        label="Nom"
                        required
                        value={nomUser}
                        onChange={(e) => {
                          setNomUser(e.target.value);
                          checkNom();
                        }}
                        variant="outlined"
                        error={nomUserError != "" ? true : false}
                        helperText={nomUserError}
                        disabled={isSavingProfile ? true : false}
                      />
                    </FormControl>
                    <FormControl variant="outlined" required={true} spacing={3}>
                      <TextField
                        className={classes.formControlName}
                        id="Prenom"
                        label="Prénom"
                        required
                        value={prenomUser}
                        onChange={(e) => {
                          setPrenomUser(e.target.value);
                          checkPrenom();
                        }}
                        variant="outlined"
                        error={prenomUserError != "" ? true : false}
                        helperText={prenomUserError}
                        disabled={isSavingProfile ? true : false}
                      />
                    </FormControl>
                  </Box>

                  <FormControl
                    variant="outlined"
                    className={classes.formControlUser}
                    required={true}
                    spacing={3}
                  >
                    <TextField
                      className={classes.formControlUser}
                      id="idRace"
                      label="Email"
                      required
                      value={email}
                      type="email"
                      onChange={function (e) {
                        setEmail(e.target.value);
                      }}
                      variant="outlined"
                      onBlur={() => checkAvailableAdress(email)}
                      error={emailError !== "" ? true : false}
                      helperText={emailError}
                      disabled={isSavingProfile ? true : false}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControlUser}
                    required={true}
                    spacing={3}
                  >
                    <TextField
                      className={classes.formControlUser}
                      id="phone"
                      label="Téléphone"
                      type="tel"
                      required
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      variant="outlined"
                      error={phoneError != "" ? true : false}
                      helperText={phoneError}
                      disabled={isSavingProfile ? true : false}
                    />
                  </FormControl>

                  {/* <PlacesAutocomplete
                    value={adresse}
                    onChange={setAdresse}
                    onSelect={handleAddressSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <TextField
                          variant="outlined"
                          onChange={() => setValidAdresse(false)}
                          className={classes.formControlUser}
                          required
                          {...getInputProps({
                            label: "Adresse",
                            placeholder: "Entrez l'adresse",
                          })}
                        />
                        <div>
                          {loading ? <div>Chargement...</div> : null}

                          {suggestions.map((suggestion, i) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? colors.brown
                                : "#fafafa",
                            };
                            return (
                              <div
                                key={i}
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete> */}
                  <Grid xs={12} sm={8} className={classes.logoUppperContainer}>
                    <div className={classes.logoUpperContainer}>
                      <h3 className={classes.logoContainerLabel}>Logo</h3>

                      <div className={classes.logoContainer}>
                        <p style={{ marginTop: 0, marginLeft: 20 }}>
                          Votre logo apparaîtra dans vos fiches
                          produits/annonces.
                        </p>
                        <div>
                          <Button
                            variant="contained"
                            onClick={() => myContainer.current.click()}
                            className={classes.btnImage}
                            disabled={isSavingProfile ? true : false}
                          >
                            Changer l'image
                          </Button>

                          <input
                            ref={myContainer}
                            style={{ visibility: "hidden" }}
                            type="file"
                            name="file_upload"
                            accept=".jpeg, .png, .jpg"
                            onChange={(e) => handleFileUpload(e)}
                          />
                        </div>
                      </div>

                      {postImage && (
                        <img
                          src={postImage.myFile || postImage}
                          width="76"
                          height="auto"
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={3}>
                  {alertMail ? (
                    <Alert severity="error" fullwidth>
                      {alertMail}
                    </Alert>
                  ) : (
                    <></>
                  )}
                  {alertOrganization ? (
                    <Alert severity="error">{alertOrganization}</Alert>
                  ) : (
                    <></>
                  )}
                  {validAdresse === false && submited === true ? (
                    <Alert severity="error">Adresse non complétée</Alert>
                  ) : (
                    <></>
                  )}
                  {profileErrMsg ? (
                    <Alert severity="error">{profileErrMsg}</Alert>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  style={{ textAlign: "center", minWidth: "100%" }}
                  item
                  xs={12}
                  sm={3}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: colors.brown }}
                    fullwidth
                    className="default-btn"
                    disabled={
                      isSavingProfile || profileFormErrors ? true : false
                    }
                  >
                    Enregistrer
                    {isSavingProfile && (
                      <CircularProgress
                        color={colors.brown}
                        size={18}
                        style={{ marginLeft: 10 }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        );
        break;
      case 2:
        return (
          <Box
            mt={10}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <form
              className={classes.formPassword}
              onSubmit={handleSubmitPassword}
            >
              <Grid
                style={{
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
                container
                spacing={1}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                  container
                  item
                  xs={12}
                  spacing={2}
                >
                  {/* <Grid item lg={4} md={6} sm={12}>
                                    <FormControl variant="outlined" className={classes.formControlPass} required={true}
                                        spacing={3} xs={6}>
                                        <TextField variant="outlined" margin="normal" required fullWidth type="password" name="oldPass" sm={12} md={12} xs={12}
                                            id="oldPswd" label="Ancien mot de passe" onChange={e => setOldPassword(e.target.value)}
                                            value={oldPassword} />
                                    </FormControl>
                                </Grid> */}
                  <Grid
                    item
                    lg={4}
                    md={6}
                    sm={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                        textAlign: "right",
                        marginRight: 10,
                        color: "#bdbdbd",
                      }}
                    >
                      Champs obligatoires
                      <Box
                        style={{
                          color: "#D94A46",
                          marginLeft: 3,
                          fontSize: 20,
                        }}
                      >
                        {" "}
                        *{" "}
                      </Box>
                    </Box>
                    <FormControl
                      variant="outlined"
                      className={classes.formControlPass}
                      required={true}
                      spacing={3}
                      xs={6}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type={showOldPassword ? "text" : "password"}
                        name="oldPass"
                        InputProps={{
                          endAdornment: (
                            <ShowPasswordButton
                              disabled={isSavingPw}
                              handleClickShowPassword={
                                handleClickShowOldPassword
                              }
                              handleMouseDownPassword={handleMouseDownPassword}
                              showPassword={showOldPassword}
                            />
                          ),
                        }}
                        id="oldPswd"
                        label="Ancien mot de passe"
                        onChange={(e) => {
                          setOldPassword(e.target.value);
                          checkEditPasswordFormErrors();
                        }}
                        value={oldPassword}
                        onInput={() => setErrorOldPassMsg("")}
                        disabled={isSavingPw ? true : false}
                      />
                      <span style={{ fontSize: 14, color: "#FF0000" }}>
                        {errorOldPassMsg}
                      </span>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControlPass}
                      required={true}
                      spacing={3}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type={showPassword1 ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <ShowPasswordButton
                              disabled={isSavingPw}
                              handleClickShowPassword={handleClickShowPassword1}
                              handleMouseDownPassword={handleMouseDownPassword}
                              showPassword={showPassword1}
                            />
                          ),
                        }}
                        name="pass1"
                        id="password1"
                        label="Nouveau mot de passe"
                        onChange={(e) => setPassword1(e.target.value)}
                        value={password1}
                        disabled={isSavingPw ? true : false}
                      />
                      <span style={{ fontSize: 14, color: "#FF0000" }}>
                        {errorPass1Msg}
                      </span>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControlPass}
                      required={true}
                      spacing={3}
                      xs={6}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type={showPassword2 ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <ShowPasswordButton
                              disabled={isSavingPw}
                              handleClickShowPassword={handleClickShowPassword2}
                              handleMouseDownPassword={handleMouseDownPassword}
                              showPassword={showPassword2}
                            />
                          ),
                        }}
                        name="pass2"
                        id="password2"
                        label="Confirmer le mot de passe"
                        onChange={(e) => setPassword2(e.target.value)}
                        value={password2}
                        disabled={isSavingPw ? true : false}
                      />
                      <span style={{ fontSize: 14, color: "#FF0000" }}>
                        {errorPassMsg}
                      </span>
                    </FormControl>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: colors.brown }}
                    className="default-btn"
                    disabled={
                      editPwFormErrors != true || isSavingPw ? true : false
                    }
                  >
                    Enregistrer
                    {isSavingPw && (
                      <CircularProgress
                        color={colors.brown}
                        size={18}
                        style={{ marginLeft: 10 }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Snackbar
                open={pwDataSaved}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setPwDataSaved(false)}
              >
                <Alert severity="success">
                  Nouveau mot de passe enrengistré
                </Alert>
              </Snackbar>
            </form>
          </Box>
        );
        break;
      case 3:
        return <Carriers></Carriers>;
    }
  }

  return (
    <div>
      <Header />
      <Container>
        <Title name={"Profil"} />
        <div className={classes.tabContainer}>
          <Button
            className={classes.btn}
            variant="contained"
            style={
              pageId === 0
                ? {
                    backgroundColor: colors.brown,
                    color: "#ffffff",
                    border: "2px solid #DEC8A6",
                  }
                : { backgroundColor: colors.white, border: "2px solid #DEC8A6" }
            }
            onClick={function (e) {
              getUserProfileData();
              setPageId(0);
            }}
            disabled={isSavingProfile || isSavingPw || !gotInfo ? true : false}
          >
            Mon profil
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            style={
              pageId === 1
                ? {
                    backgroundColor: colors.brown,
                    color: "#ffffff",
                    border: "2px solid #DEC8A6",
                  }
                : { backgroundColor: colors.white, border: "2px solid #DEC8A6" }
            }
            onClick={function (e) {
              getUserProfileData();
              setPageId(1);
            }}
            disabled={isSavingProfile || isSavingPw || !gotInfo ? true : false}
          >
            Modifier mon profil
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            style={
              pageId === 2
                ? {
                    backgroundColor: colors.brown,
                    color: "#ffffff",
                    border: "2px solid #DEC8A6",
                  }
                : { backgroundColor: colors.white, border: "2px solid #DEC8A6" }
            }
            onClick={function (e) {
              getUserProfileData();
              setPageId(2);
            }}
            disabled={isSavingProfile || isSavingPw || !gotInfo ? true : false}
          >
            Changer le mot de passe
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            style={
              pageId === 3
                ? {
                    backgroundColor: colors.brown,
                    color: "#ffffff",
                    border: "2px solid #DEC8A6",
                  }
                : { backgroundColor: colors.white, border: "2px solid #DEC8A6" }
            }
            onClick={function (e) {
              setPageId(3);
            }}
            disabled={isSavingProfile || isSavingPw || !gotInfo ? true : false}
          >
            Mes transporteurs
          </Button>
        </div>
        <Box className={classes.boxBorder} mt={5}>
          {getPageContent()}
        </Box>
      </Container>
    </div>
  );
}
export default UserProfile;
