import React from 'react'
import { useSelector } from 'react-redux';
import { Redirect } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom';


function ProtectedRoute({children}) {
  let location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const key = searchParams.get("key");

  const Mail = useSelector((state) => state.Mail);

      if (!localStorage.getItem('accessToken') && children && email !== "null" && key !== "null" ) {
        return(children)
    }
    else return <Redirect to={{
        pathname: "/"
    }}/>
    // else return <Redirect to={{
    //     pathname: "/forgot"
    // }}/>
}

export default ProtectedRoute;
