import * as Yup from "yup";
import { fr } from "yup-locales";
import { setLocale } from "yup";
import { useState } from "react";
import misterCoockyApi from "../../../constante/apiUrl";
import { useSelector } from "react-redux";

setLocale(fr);

export function UseRegister() {
  const [pageId, setPageId] = useState(0); //numéro de page du formulaire d'inscription (0,1,2)
  const [alertMail, setAlertMail] = useState(""); //message d'alerte au niveau de l'adresse mail
  const [ChampVide, setChampVide] = useState(""); //message d'alerte au niveau chmpvide
  const [alertOrga, setAlertOrga] = useState(""); 
  const [formError,setFormError] = useState("");
  const TypeLenseigne = [
    { key: "1", value: "Artisan" },
    { key: "2", value: "Magasin spécialisé" },
    { key: "3", value: "Magasin généraliste" },
    { key: "4", value: "Association" },
    { key: "5", value: "Marque" },
    { key: "6", value: "Marketplace" },
  ];

  const TypeOffre = [
    { key: "1", value: "Alimentation" },
    { key: "2", value: "Accessoires" },
    { key: "3", value: "Services" },
  ];
  const typeInscrire = [
    { key: "1", value: "Je souhaite souscrire au label Sain, Malin, Responsable" },
  ];
  const AddressesOption = [
    { key: "Select an option", value: "" },
    { key: "Option 1", value: "option1" },
    { key: "Option 2", value: "option2" },
    { key: "Option 3", value: "option3" },
  ];
  const registerValues = {
    nom: "",
    prenom: "",
    email: "",
    phone: "",
    password: "",
    cfpassword: "",
    TypeLenseigne: [],
    nomentreprise: "",
    address: "",
    offre: [],
    inscrire: [],
  };
  const Address = useSelector((state) => state.Address);
  const { Adresses, Cordinates } = Address;
  const lowercaseRegEx = /(?=.*[a-z])/;
  const uppercaseRegEx = /(?=.*[A-Z])/;
  const numericRegEx = /(?=.*[0-9])/;
  const passwordRegEx = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{4,}$/;
  const emailRegex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"

  //const phoneRegex = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  
  let RegsiterSchema = Yup.object().shape({
    nom: Yup.string()
      .required("Le nom est requis")
      .min(1, "Le nom est trop court - doit comporter au moins 1 caractère.")
      .max(256, "Le nom est trop long - doit être de 256 caractères maximum."),
    prenom: Yup.string()
      .required("Le prénom est requis")
      .min(1, "Le prénom est trop court - doit comporter au moins 1 caractère.")    
      .max(256, "Le prénom est trop long - doit être de 256 caractères maximum."),
    email: Yup.string()
      .matches(emailRegex, "L'adresse e-mail doit être valide !")
      .required("L'adresse e-mail est requise")
      .min(4, "L'adresse e-mail est trop courte - doit comporter au moins 4 caractères.")
      .max(320, "L'adresse e-mail est trop longue - doit être de 320 caractères maximum."),
    phone: Yup.string()
      .required("Le numéro de téléphone est requis")
      .length(10, "Le numéro de téléphone est invalide - doit comporter 10 chiffres.")
      .matches(phoneRegex, "Le numéro de téléphone doit être valide !"),
    nomentreprise: Yup.string()
      .required("Le nom d'entreprise est requis")
      .min(1, "Le nom d'entreprise est trop court - doit comporter au moins 1 caractère.")
      .max(256, "Le nom d'entreprise est trop long - doit être de 256 caractères maximum."),
    password: Yup.string()
       .min(4, "Le mot de passe est trop court - doit comporter au moins 4 caractères.")
       .matches(lowercaseRegEx, "Doit contenir des lettres minuscules !")
       .matches(uppercaseRegEx, "Doit contenir des lettres majuscules !")
       .matches(numericRegEx, "Doit contenir des chiffres !")
       .matches(passwordRegEx, "Doit contenir des chiffres !")
       //.matches(specialsRegEx, "Doit contenir un caractère spécial !")
       .required("Le mot de passe est requis"),
    cfpassword: Yup.string()
       .required("Confirmez le mot de passe")
       .oneOf([Yup.ref("password")], "Les mots de passe ne correspondent pas"),
    TypeLenseigne: Yup.array().required("Le type de l'enseigne est requis"),
    offre: Yup.array().required("Le type d'offres est requis"),
    address: Yup.string().required("Adresse requise"),
  });

  const fetchEmail = (email) => {
    fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
            query{​​​​​​​
                getMail(mail:"${email}"){​​​​​​​
                    id
                }​​​​​​​
            }`,
      }),
    })
      .then((res) => res.json())
      .then(function (result) {
        if (result.data.getMail !== null) {
          console.log(result.data.getMail);
          setAlertMail("");
          setAlertMail("Email déjà utilisée !");
          
        } else {
          console.log("Result of getMail : " + result.data.getMail)
          setAlertMail("");
          setPageId(pageId + 1);
        }
      });
  };

  async function checkAvailableOrganization(orga) {
    await fetch(`${misterCoockyApi.misterCoockyApi}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
                    query{
                        existsOrganizationByName(name:"${orga}")
                    }`,
      }),
    })
      .then((res) => res.json())
      .then(function (result) {
        if (result.data.existsOrganizationByName === true) {
          setAlertOrga("Cette organisation existe déjà !");
        } else if (result.data.existsOrganizationByName === false) {
          setAlertOrga("");
        }
      });

    }

  const onSubmit = () => {
    setPageId(pageId + 1);
  };
  const onReturn = () => {
    setPageId(pageId - 1);
  };

  const ActivateAlert = () => {
    setChampVide("Champs vides ou non valides !");
  };

  async function ValidateRegister(value, formikAction) {
    let URL = `${misterCoockyApi.misterCoockyApi}/graphql`;
      fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `mutation($organization: OrganizationInput!, $adress:AdressInput!, $user:UserIntput!, $smrSubscription: Boolean!){
                          registerUserPro( organization: $organization,  adress:$adress, user:$user, smrSubscription: $smrSubscription)
                          {​​​​​​
                                  id
                                  organization{
                                      id
                                      supplier{
                                          id
                                      }
                                  }
                          }​​​​​​​
                      }`,
          variables: {
            "organization": {
              "name": value.nomentreprise,
              "orgas": value.TypeLenseigne,
              "offers": value.offre,
            },
            "adress": {
              "street": Address.Adresses,
              "geoLocation": {
                "latitude": Address.Cordinates.lat,
                "longitude": Address.Cordinates.lng,
              },
            },
            "user": {
              "lastName": value.prenom,
              "firstName": value.nom,
              "email": value.email,
              "phone": value.phone,
              "password": value.password,
            },
            "smrSubscription": true
          },
        }),
      })
        .then((res) => res.json())
        .then(result => {
          if (result.data.registerUserPro !== []) {
            // localStorage.setItem('userId', result.data.registerUserPro.id);
            // localStorage.setItem('organizationId', result.data.registerUserPro.organization.id);
            // localStorage.setItem('supplierId', result.data.registerUserPro.organization.supplier.id);
            formikAction.setSubmitting(false);
            formikAction.resetForm();
            window.location.href = '/signIn';
          }
        })
        .catch(e => {
          setFormError("Erreur");
        });
    

  };


  const Validate = (value) => {
    console.log('FromUSR', Adresses, Cordinates)
    console.log('value', value)
  }
  return {
    registerValues,
    RegsiterSchema,
    fetchEmail,
    checkAvailableOrganization,
    alertMail,
    pageId,
    onReturn,
    onSubmit,
    TypeLenseigne,
    AddressesOption,
    TypeOffre,
    typeInscrire,
    ChampVide,
    alertOrga,
    formError,
    ActivateAlert,
    ValidateRegister,
    Validate
  };
}
